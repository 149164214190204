var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import * as Typeahead from './Typeahead';
import { ControllerContext } from 'nuri/components';
var SearchInput = /** @class */ (function (_super) {
    __extends(SearchInput, _super);
    function SearchInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.input = React.createRef();
        _this._onSelect = function (title) {
            _this.$.typeahead('close').typeahead('val', '');
            if (_this.props.onSelect) {
                _this.props.onSelect(title);
            }
            else {
                var path = '/works/' + encodeURIComponent(title) + '/';
                if (_this.context) {
                    _this.context.load({ path: path, query: {} });
                }
                else {
                    location.href = path;
                }
            }
        };
        return _this;
    }
    SearchInput.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var onSelect, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        onSelect = this._onSelect;
                        _a = this;
                        return [4 /*yield*/, Typeahead.init(this.input.current, { highlight: true, hint: false }, {
                                source: Typeahead.searchSource,
                                displayKey: 'title',
                                templates: Typeahead.templates,
                            })];
                    case 1:
                        _a.$ = (_b.sent())
                            .on('typeahead:selected', function (_, item) {
                            onSelect(item.title);
                        })
                            .on('keypress', function (event) {
                            if (event.keyCode == 13) {
                                var self = this;
                                var q = self.value;
                                Typeahead.searchSource(q, function (data) {
                                    if (q != self.value || data.length === 0)
                                        return;
                                    if (data.filter(function (item) {
                                        return item.title == q;
                                    }).length == 1)
                                        onSelect(q);
                                    else
                                        onSelect(data[0].title);
                                });
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchInput.prototype.componentWillUnmount = function () {
        if (this.$)
            this.$.typeahead('destroy');
    };
    SearchInput.prototype.render = function () {
        return React.createElement("input", { type: "search", placeholder: "\uAC80\uC0C9\uD560 \uC791\uD488\uBA85 \uC785\uB825", ref: this.input, className: "tt-input" });
    };
    SearchInput.contextType = ControllerContext;
    return SearchInput;
}(React.Component));
export default SearchInput;
