var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { deleteFrontendSession } from '../API';
import * as Layout from '../ui/Layout';
import Styles from './Settings.less';
import { API } from '../ApiClient';
import { SettingsRouteDocument } from './__generated__/Settings.graphql';
import { AppLayout } from '../layouts/AppLayout';
var ChangePassword = /** @class */ (function (_super) {
    __extends(ChangePassword, _super);
    function ChangePassword() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.oldPasswordInput = React.createRef();
        _this.newPassword1Input = React.createRef();
        _this.newPassword2Input = React.createRef();
        _this.state = {
            isChangingPassword: false,
        };
        return _this;
    }
    ChangePassword.prototype.render = function () {
        return (React.createElement("table", { className: Styles.changePasswordForm },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("th", null,
                        React.createElement("label", { htmlFor: "id_old_password" }, "\uAE30\uC874 \uC554\uD638")),
                    React.createElement("td", null,
                        React.createElement("input", { id: "id_old_password", ref: this.oldPasswordInput, type: "password" }))),
                React.createElement("tr", null,
                    React.createElement("th", null,
                        React.createElement("label", { htmlFor: "id_new_password1" }, "\uC0C8 \uC554\uD638")),
                    React.createElement("td", null,
                        React.createElement("input", { id: "id_new_password1", ref: this.newPassword1Input, type: "password" }))),
                React.createElement("tr", null,
                    React.createElement("th", null,
                        React.createElement("label", { htmlFor: "id_new_password2" }, "\uC0C8 \uC554\uD638 \uD655\uC778")),
                    React.createElement("td", null,
                        React.createElement("input", { id: "id_new_password2", ref: this.newPassword2Input, type: "password" }))),
                React.createElement("tr", null,
                    React.createElement("th", null),
                    React.createElement("td", null,
                        React.createElement("button", { onClick: this._changePassword.bind(this), disabled: this.state.isChangingPassword }, "\uBC14\uAFB8\uAE30"))))));
    };
    ChangePassword.prototype._changePassword = function () {
        var _this = this;
        var _a, _b, _c;
        if (!((_a = this.newPassword1Input.current) === null || _a === void 0 ? void 0 : _a.value)) {
            alert('변경하실 암호를 입력하세요.');
            return;
        }
        if (((_b = this.newPassword1Input.current) === null || _b === void 0 ? void 0 : _b.value) !== ((_c = this.newPassword2Input.current) === null || _c === void 0 ? void 0 : _c.value)) {
            alert('새 암호 확인을 정확히 입력하세요.');
            return;
        }
        this.setState({ isChangingPassword: true });
        API.call('/api/v5/Settings/changePassword', {
            oldPassword: this.oldPasswordInput.current.value,
            newPassword: this.newPassword1Input.current.value
        })
            .then(function () {
            alert('암호를 변경했습니다.');
            _this.oldPasswordInput.current.value = '';
            _this.newPassword1Input.current.value = '';
            _this.newPassword2Input.current.value = '';
            _this.setState({ isChangingPassword: false });
        }, function () {
            _this.setState({ isChangingPassword: false });
        });
    };
    return ChangePassword;
}(React.Component));
var SettingsRoute = /** @class */ (function (_super) {
    __extends(SettingsRoute, _super);
    function SettingsRoute() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            backupState: null,
        };
        _this._disconnectTwitter = function (event) {
            event.preventDefault();
            API.call('/api/v5/Settings/disconnectTwitter', {}).then(function () {
                _this.props.writeData(function (data) {
                    data.currentUser.isTwitterConnected = false;
                });
            });
        };
        _this._downloadBackup = function (event) {
            event.preventDefault();
            _this.setState({ backupState: 'preparing' });
            API.call('/api/v5/Settings/createBackup', {}).then(function (result) {
                location.href = result.downloadUrl;
                _this.setState({ backupState: 'completed' });
            }, function () { return _this.setState({ backupState: null }); });
        };
        _this._logout = function (event) {
            event.preventDefault();
            deleteFrontendSession().then(function () {
                location.href = '/';
            });
        };
        return _this;
    }
    SettingsRoute.prototype.render = function () {
        var currentUser = this.props.data.currentUser;
        return (React.createElement(Layout.CenteredFullWidth, null,
            React.createElement("div", { className: Styles.section },
                React.createElement("h2", { className: Styles.sectionTitle }, "\uD2B8\uC704\uD130 \uC5F0\uB3D9"),
                React.createElement("div", { className: Styles.action }, currentUser.isTwitterConnected ? React.createElement(React.Fragment, null,
                    "\uC0C1\uD0DC: \uC5F0\uACB0\uB428",
                    React.createElement("br", null),
                    React.createElement("a", { href: "#", onClick: this._disconnectTwitter }, "\uC5F0\uACB0 \uB04A\uAE30")) : React.createElement(React.Fragment, null, "\uD2B8\uC704\uD130 API \uC720\uB8CC\uD654\uB85C \uC5F0\uB3D9 \uAE30\uB2A5 \uC81C\uACF5\uC744 \uC911\uB2E8\uD569\uB2C8\uB2E4."))),
            React.createElement("div", { className: Styles.section },
                React.createElement("h2", { className: Styles.sectionTitle }, "\uB0B4\uBCF4\uB0B4\uAE30"),
                React.createElement("div", { className: Styles.action },
                    "\uBAA8\uB4E0 \uAE30\uB85D\uC744 CSV \uD615\uC2DD\uC73C\uB85C \uBC1B\uC744 \uC218 \uC788\uC2B5\uB2C8\uB2E4.",
                    React.createElement("br", null),
                    this.state.backupState === 'preparing' ?
                        React.createElement("em", null, "\uD30C\uC77C\uC744 \uC900\uBE44\uD558\uB294 \uC911\uC785\uB2C8\uB2E4. \uC7A0\uC2DC \uAE30\uB2E4\uB824 \uC8FC\uC138\uC694.") :
                        (!this.state.backupState && React.createElement("a", { href: "#", onClick: this._downloadBackup }, "\uB2E4\uC6B4\uB85C\uB4DC")))),
            React.createElement("div", { className: Styles.section },
                React.createElement("h2", { className: Styles.sectionTitle }, "\uC554\uD638 \uBC14\uAFB8\uAE30"),
                React.createElement("div", { className: Styles.sectionContent },
                    React.createElement(ChangePassword, null))),
            React.createElement("a", { href: "#", className: Styles.logoutButton, onClick: this._logout }, "\uB85C\uADF8\uC544\uC6C3")));
    };
    return SettingsRoute;
}(React.Component));
var routeHandler = AppLayout({ activeMenu: 'user' }).wrap({
    component: SettingsRoute,
    load: function (_a) {
        var loader = _a.loader, redirect = _a.redirect;
        return __awaiter(this, void 0, void 0, function () {
            var data, currentUser;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, loader.graphql(SettingsRouteDocument)];
                    case 1:
                        data = _b.sent();
                        currentUser = data.currentUser;
                        if (!currentUser)
                            return [2 /*return*/, redirect('/login/')];
                        return [2 /*return*/, __assign(__assign({}, data), { currentUser: currentUser })];
                }
            });
        });
    },
});
export default routeHandler;
