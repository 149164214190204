import * as Sentry from '@sentry/react';
import { bootstrap } from 'nuri/client';
import nprogress from 'nprogress';
import app from './routes';
import { graphql } from './API';
import { trackPageView } from './Tracking';
import '../less/nprogress.less';
import '../less/base.less';
import { config as faConfig } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { API } from './ApiClient';
import { errorHandler } from './routes/errorHandler';
faConfig.autoAddCss = false;
if (window.SENTRY_DSN) {
    Sentry.init({
        dsn: window.SENTRY_DSN,
        ignoreErrors: ['hideGuidePopup'],
    });
}
if (process.env.NODE_ENV === 'development') {
    var worker = require('./mocks/browser').worker;
    worker.start();
}
var loader = {
    v5: API,
    graphql: graphql,
};
bootstrap(app, loader, errorHandler, function (controller) {
    nprogress.configure({
        trickleRate: 0.4,
        trickleSpeed: 600,
        easing: 'ease',
    });
    controller.subscribe({
        willLoad: function () {
            nprogress.start();
        },
        didLoad: function () {
            nprogress.done();
        },
        didAbortLoad: function () {
            nprogress.done();
        },
        didCommitState: function () {
            trackPageView();
        },
    });
});
