var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* global confirm */
import React from 'react';
import { CurrentUserLayout } from '../layouts/CurrentUserLayout';
import { Sortable } from '../ui/Sortable';
import { CenteredFullWidth } from '../ui/Layout';
import { graphql } from '../API';
import Styles from './ManageCategory.less';
import { ManageCategoryRouteDocument, ManageCategory_CreateCategoryDocument, ManageCategory_DeleteCategoryDocument, ManageCategory_RenameCategoryDocument, ManageCategory_UpdateCategoryOrderDocument } from './__generated__/ManageCategory.graphql';
var CategoryItem = /** @class */ (function (_super) {
    __extends(CategoryItem, _super);
    function CategoryItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isEditing: false,
            name: '',
        };
        _this._onChange = function (event) {
            _this.setState({ name: event.target.value });
        };
        _this._startEditing = function () {
            _this.setState({ isEditing: true, name: _this.props.category.name });
        };
        _this._endEditing = function () {
            _this.setState({ isEditing: false });
        };
        _this._onSubmit = function (event) {
            event.preventDefault();
            _this.props.onRename(_this.state.name).then(function () { return _this._endEditing(); });
        };
        return _this;
    }
    CategoryItem.prototype.render = function () {
        var itemClassName = this.props.isSorting
            ? Styles.sortingItem
            : Styles.item;
        if (!this.props.isSorting && this.state.isEditing)
            return (React.createElement("div", { className: itemClassName },
                React.createElement("form", { onSubmit: this._onSubmit },
                    React.createElement("input", { value: this.state.name, onChange: this._onChange, maxLength: 30 }),
                    React.createElement("button", { type: "submit" }, "\uBCC0\uACBD"),
                    React.createElement("span", { className: Styles.itemAction, onClick: this._endEditing }, "\uCDE8\uC18C"))));
        else {
            var actions;
            if (!this.props.isSorting) {
                actions = [
                    React.createElement("span", { className: Styles.itemAction, onClick: this._startEditing }, "\uC774\uB984 \uBC14\uAFB8\uAE30"),
                    React.createElement("span", { className: Styles.itemAction, onClick: this.props.onRemove }, "\uC0AD\uC81C"),
                ];
            }
            return (React.createElement("div", { className: itemClassName },
                React.createElement("span", { className: Styles.itemName }, this.props.category.name),
                actions));
        }
    };
    return CategoryItem;
}(React.Component));
var ManageCategory = /** @class */ (function (_super) {
    __extends(ManageCategory, _super);
    function ManageCategory() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.nameInput = React.createRef();
        _this.state = {
            sortingCategories: null,
        };
        _this._renderItem = function (category) {
            var isSorting = _this.state.sortingCategories != null;
            return (React.createElement(CategoryItem, { key: category.databaseId, category: category, isSorting: isSorting, onRemove: function () { return _this._removeCategory(category); }, onRename: function (name) { return _this._renameCategory(category, name); } }));
        };
        _this._beginSorting = function () {
            _this.setState({ sortingCategories: _this.props.data.currentUser.categories });
        };
        _this._endSorting = function () {
            var categoryIds = _this.state.sortingCategories.map(function (c) { return c.databaseId; });
            graphql(ManageCategory_UpdateCategoryOrderDocument, { input: { categoryIds: categoryIds } }).then(function (result) {
                _this.setState({ sortingCategories: null });
                _this.props.writeData(function (data) {
                    data.currentUser.categories = result.updateCategoryOrder.categories;
                });
            });
        };
        _this._onSwap = function (i, j) {
            var nextList = _this.state.sortingCategories.slice();
            var temp = nextList[i];
            nextList[i] = nextList[j];
            nextList[j] = temp;
            _this.setState({ sortingCategories: nextList });
        };
        _this._addCategory = function (event) {
            event.preventDefault();
            var input = _this.nameInput.current;
            graphql(ManageCategory_CreateCategoryDocument, { input: { name: input.value } }).then(function (result) {
                input.value = '';
                _this.props.writeData(function (data) {
                    data.currentUser.categories.push(result.createCategory.category);
                });
            });
        };
        _this._removeCategory = function (category) {
            if (confirm('분류를 삭제해도 기록은 삭제되지 않습니다.\n분류를 삭제하시려면 [확인]을 누르세요.')) {
                graphql(ManageCategory_DeleteCategoryDocument, { input: { categoryId: category.databaseId } }).then(function () {
                    _this.props.writeData(function (data) {
                        data.currentUser.categories = data.currentUser.categories.filter(function (c) { return c.databaseId !== category.databaseId; });
                    });
                });
            }
        };
        _this._renameCategory = function (category, name) {
            return graphql(ManageCategory_RenameCategoryDocument, { input: { categoryId: category.databaseId, name: name } }).then(function () {
                _this.props.writeData(function () {
                    category.name = name;
                });
            });
        };
        return _this;
    }
    ManageCategory.prototype.render = function () {
        var isSorting = this.state.sortingCategories != null;
        return (React.createElement(CenteredFullWidth, null,
            React.createElement("div", { className: Styles.title }, "\uBD84\uB958 \uAD00\uB9AC"),
            isSorting ? (React.createElement(Sortable, { onSwap: this._onSwap }, this.state.sortingCategories.map(this._renderItem))) : (this.props.data.currentUser.categories.map(this._renderItem)),
            React.createElement("div", { className: Styles.sort }, isSorting ? (React.createElement("div", null,
                "\uD56D\uBAA9\uC744 \uB4DC\uB798\uADF8\uD558\uC5EC \uC21C\uC11C\uB97C \uBC14\uAFC0 \uC218 \uC788\uC2B5\uB2C8\uB2E4.",
                ' ',
                React.createElement("button", { className: Styles.button, onClick: this._endSorting }, "\uC800\uC7A5"))) : (React.createElement("button", { className: Styles.button, onClick: this._beginSorting }, "\uC21C\uC11C \uBC14\uAFB8\uAE30"))),
            !isSorting && (React.createElement("div", null,
                React.createElement("div", { className: Styles.title }, "\uBD84\uB958 \uCD94\uAC00"),
                React.createElement("form", { onSubmit: this._addCategory },
                    "\uBD84\uB958 \uC774\uB984: ",
                    React.createElement("input", { size: 12, ref: this.nameInput, maxLength: 30 }),
                    React.createElement("button", { className: Styles.button }, "\uCD94\uAC00"))))));
    };
    return ManageCategory;
}(React.Component));
var routeHandler = CurrentUserLayout.wrap({
    component: ManageCategory,
    load: function (_a) {
        var loader = _a.loader;
        return __awaiter(this, void 0, void 0, function () {
            var _b, currentUser, data;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, loader.graphql(ManageCategoryRouteDocument)];
                    case 1:
                        _b = _c.sent(), currentUser = _b.currentUser, data = __rest(_b, ["currentUser"]);
                        // TODO: redirect to login page
                        if (!currentUser) {
                            throw new Error('Login required.');
                        }
                        return [2 /*return*/, __assign(__assign({}, data), { currentUser: currentUser })];
                }
            });
        });
    },
});
export default routeHandler;
