var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { plusOne } from '../util';
import Styles from './StatusInput.less';
var StatusInput = /** @class */ (function (_super) {
    __extends(StatusInput, _super);
    function StatusInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._onChange = function (event) {
            if (_this.props.onChange)
                _this.props.onChange(event.target.value);
        };
        _this._onClickPlus = function (event) {
            event.preventDefault();
            _this.props.onChange(plusOne(_this.props.value));
        };
        return _this;
    }
    StatusInput.prototype.render = function () {
        var _a = this.props, style = _a.style, value = _a.value, _b = _a.minSize, minSize = _b === void 0 ? 3 : _b, _c = _a.maxSize, maxSize = _c === void 0 ? 10 : _c, props = __rest(_a, ["style", "value", "minSize", "maxSize"]);
        var showSuffix = value.match(/^(|.*\d)$/);
        var width = Math.max(minSize, Math.min(maxSize, value.length)) +
            'em';
        style = __assign(__assign({}, style), { width: width, textAlign: 'right' });
        return (React.createElement(React.Fragment, null,
            React.createElement("input", __assign({}, props, { style: style, value: value, onChange: this._onChange })),
            showSuffix ? '화' : null,
            React.createElement("span", { className: Styles.plus, style: { cursor: 'pointer' }, onClick: this._onClickPlus })));
    };
    return StatusInput;
}(React.Component));
export { StatusInput };
