var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { WeeklyChartFragmentDoc } from '../../ui/__generated__/WeeklyChart.graphql';
import { Post_PostFragmentDoc } from '../../ui/__generated__/Post.graphql';
import { PostComment_PostFragmentDoc } from '../../ui/__generated__/GqlPostComment.graphql';
export var IndexRoute_TimelinePostFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "IndexRoute_timelinePost" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "Post" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }, { "kind": "FragmentSpread", "name": { "kind": "Name", "value": "Post_post" } }] } }] };
export var IndexRouteDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "IndexRoute" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "count" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "Int" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "timeline" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "count" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "count" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "IndexRoute_timelinePost" } }] } }, { "kind": "FragmentSpread", "name": { "kind": "Name", "value": "WeeklyChart" } }] } }], IndexRoute_TimelinePostFragmentDoc.definitions, true), Post_PostFragmentDoc.definitions, true), PostComment_PostFragmentDoc.definitions, true), WeeklyChartFragmentDoc.definitions, true) };
export var IndexRoute_MoreTimelineDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "IndexRoute_moreTimeline" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "timelineBeforeId" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "count" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "Int" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "timeline" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "beforeId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "timelineBeforeId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "count" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "count" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "IndexRoute_timelinePost" } }] } }] } }], IndexRoute_TimelinePostFragmentDoc.definitions, true), Post_PostFragmentDoc.definitions, true), PostComment_PostFragmentDoc.definitions, true) };
