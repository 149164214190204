var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { AppLayout } from '../layouts/AppLayout';
import * as Grid from '../ui/Grid';
import { LoadMore } from '../ui/LoadMore';
import Styles from './Index.module.less';
import { Post } from '../ui/Post';
import { IndexRouteDocument, IndexRoute_MoreTimelineDocument } from './__generated__/Index.graphql';
import { WeeklyChart } from '../ui/WeeklyChart';
var Index = function (_a) {
    var data = _a.data, writeData = _a.writeData, loader = _a.loader;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    return (React.createElement(Grid.Row, null,
        React.createElement(Grid.Column, { size: 8, pull: "left" }, _renderTimeline(data.timeline)),
        React.createElement(Grid.Column, { size: 4, pull: "right", className: Styles.sidebar },
            React.createElement("h2", { className: Styles.sectionTitle }, "\uC8FC\uAC04 \uC778\uAE30 \uC791\uD488"),
            React.createElement(WeeklyChart, { data: data }))));
    function _renderTimeline(timeline) {
        return (React.createElement("div", { className: Styles.timeline },
            React.createElement("h2", { className: Styles.sectionTitle }, "\uCD5C\uADFC \uAC10\uC0C1\uD3C9"),
            timeline.map(function (post) { return React.createElement(Post, { key: post.databaseId, post: post }); }),
            React.createElement(LoadMore, { onClick: _loadMore, isLoading: isLoading })));
    }
    function _loadMore() {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, loader.graphql(IndexRoute_MoreTimelineDocument, {
                                timelineBeforeId: ((_a = data === null || data === void 0 ? void 0 : data.timeline) === null || _a === void 0 ? void 0 : _a.length) ? data.timeline[data.timeline.length - 1].databaseId : null,
                                count: 32,
                            })];
                    case 1:
                        result = _b.sent();
                        writeData(function (data) {
                            data.timeline = data.timeline.concat(result.timeline);
                        });
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    }
};
var routeHandler = AppLayout({ activeMenu: 'home' }).wrap({
    component: Index,
    load: function (_a) {
        var loader = _a.loader;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                return [2 /*return*/, loader.graphql(IndexRouteDocument, { count: 10 })];
            });
        });
    },
});
export default routeHandler;
