import React from 'react';
import { Link } from 'nuri';
import { getWorkURL } from '../util';
import Styles from './WeeklyChart.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
export function WeeklyChart(_a) {
    var data = _a.data;
    return (React.createElement("div", null, data.weeklyWorksChart.map(function (item) {
        var diff;
        if (item.diff) {
            if (item.sign === -1) {
                diff = (React.createElement("span", { className: Styles.down },
                    React.createElement(FontAwesomeIcon, { icon: faArrowDown }),
                    " ",
                    item.diff));
            }
            else if (item.sign === +1) {
                diff = (React.createElement("span", { className: Styles.up },
                    React.createElement(FontAwesomeIcon, { icon: faArrowUp }),
                    " ",
                    item.diff));
            }
        }
        var work = item.work;
        return (React.createElement(Link, { key: work.databaseId, to: getWorkURL(work.title), className: Styles.item },
            work.imageUrl && (React.createElement("div", { className: Styles.poster },
                React.createElement("img", { src: work.imageUrl, className: Styles.posterImage }))),
            React.createElement("div", { className: Styles.content },
                React.createElement("div", { className: Styles.title }, work.title),
                diff ? (React.createElement("div", { className: Styles.rank },
                    item.rank,
                    "\uC704 \u00B7 ",
                    diff)) : (React.createElement("div", { className: Styles.rank },
                    item.rank,
                    "\uC704")))));
    })));
}
