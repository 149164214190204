import * as React from 'react';
import { Link } from 'nuri';
import * as util from '../util';
import Styles from './WorkStatusButton.less';
import LoginDialog from './LoginDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
export function WorkStatusButton(_a) {
    var work = _a.work, record = _a.record, currentUser = _a.currentUser;
    if (record) {
        return (React.createElement(Link, { className: Styles.editButton, to: "/records/".concat(record.databaseId, "/") },
            React.createElement(FontAwesomeIcon, { icon: faPencil }),
            record.statusType && util.GQL_STATUS_TYPE_TEXT[record.statusType],
            record.status && (React.createElement("span", { className: Styles.editButtonSubtext },
                "@ ",
                util.getStatusDisplayGql(record)))));
    }
    else {
        return (React.createElement(Link, { className: Styles.addButton, to: '/records/add/' + encodeURIComponent(work.title) + '/', queryParams: { ref: 'Work' }, stacked: true, onClick: currentUser ? undefined : function (event) { event.preventDefault(); LoginDialog.open(); } },
            React.createElement(FontAwesomeIcon, { icon: faPlus }),
            "\uC791\uD488 \uCD94\uAC00"));
    }
}
