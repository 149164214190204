var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import cx from 'classnames';
import { Link } from 'nuri';
import { default as dateFnsFormat } from 'date-fns/format';
import { filter } from 'rxjs/operators';
import * as Grid from './Grid';
import * as util from '../util';
import { LoadMore } from './LoadMore';
import { WorkStatusButton } from './WorkStatusButton';
import VideoSearch from './VideoSearch';
import Styles from './WorkViews.less';
import * as Mutations from '../Mutations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faComment, faExternalLink, faUser } from '@fortawesome/free-solid-svg-icons';
import { WeeklyChart } from './WeeklyChart';
import { Post } from './Post';
function Sidebar(_a) {
    var _b;
    var work = _a.work, chart = _a.chart, episode = _a.episode;
    var metadata = work.metadata;
    var videoQuery = work.title;
    if (episode) {
        videoQuery += ' ' + episode + '화';
    }
    var jpSchedule = (_b = metadata === null || metadata === void 0 ? void 0 : metadata.schedules) === null || _b === void 0 ? void 0 : _b.find(function (it) { return it.country === 'jp'; });
    return (React.createElement("div", { className: Styles.sidebar },
        metadata && (React.createElement("div", { className: Styles.metadataSection },
            React.createElement("h2", { className: Styles.sectionTitle }, "\uC791\uD488 \uC815\uBCF4"),
            React.createElement("div", { className: Styles.metadata },
                React.createElement("p", null,
                    metadata.studioNames && React.createElement("b", null, metadata.studioNames.join(', ')),
                    metadata.studioNames && ' 제작',
                    metadata.source && (' / ' + util.SOURCE_TYPE_MAP[metadata.source])),
                (jpSchedule === null || jpSchedule === void 0 ? void 0 : jpSchedule.date) && (React.createElement("p", null,
                    React.createElement(FontAwesomeIcon, { icon: faCalendarAlt }),
                    " \uCCAB \uBC29\uC601:",
                    ' ',
                    dateFnsFormat(jpSchedule.date, 'YYYY-MM-DD'))),
                React.createElement("div", { className: Styles.metadataLinks },
                    metadata.websiteUrl && (React.createElement("p", null,
                        React.createElement(FontAwesomeIcon, { icon: faExternalLink }),
                        " ",
                        ' ',
                        React.createElement("a", { href: metadata.websiteUrl, target: "_blank" }, "\uACF5\uC2DD \uC0AC\uC774\uD2B8"))),
                    metadata.namuwikiUrl && (React.createElement("p", null,
                        React.createElement(FontAwesomeIcon, { icon: faExternalLink }),
                        " ",
                        ' ',
                        React.createElement("a", { href: metadata.namuwikiUrl, target: "_blank" }, "\uB098\uBB34\uC704\uD0A4"))),
                    metadata.annUrl && (React.createElement("p", null,
                        React.createElement(FontAwesomeIcon, { icon: faExternalLink }),
                        " ",
                        ' ',
                        React.createElement("a", { href: metadata.annUrl, target: "_blank" }, "AnimeNewsNetwork (\uC601\uBB38)"))))))),
        React.createElement("div", { className: Styles.videosSection },
            React.createElement("h2", { className: Styles.sectionTitle }, "\uB3D9\uC601\uC0C1"),
            React.createElement(VideoSearch, { query: videoQuery })),
        React.createElement("h2", { className: Styles.sectionTitle }, "\uC8FC\uAC04 \uC778\uAE30 \uC791\uD488"),
        React.createElement(WeeklyChart, { data: chart })));
}
var Work = /** @class */ (function (_super) {
    __extends(Work, _super);
    function Work() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Work.prototype.componentDidMount = function () {
        var _this = this;
        // TODO: move subscription up to route
        if (this.props.currentUser) {
            this._subscription = Mutations.records
                .pipe(filter(function (it) { var _a; return it.workId === _this.props.work.databaseId && it.userId === ((_a = _this.props.currentUser) === null || _a === void 0 ? void 0 : _a.databaseId); }))
                .subscribe(function (it) {
                _this.props.onRecordChange(it.id);
            });
        }
    };
    Work.prototype.componentWillUnmount = function () {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    };
    Work.prototype.render = function () {
        var work = this.props.work;
        return (React.createElement("div", { className: Styles.container },
            React.createElement(Grid.Row, null,
                React.createElement(Grid.Column, { size: 8, pull: "left" },
                    React.createElement("div", { className: Styles.main },
                        this._renderHeader(),
                        React.createElement("div", { className: Styles.content }, this.props.children))),
                React.createElement(Grid.Column, { size: 4, pull: "right" },
                    React.createElement(Sidebar, { work: work, episode: this.props.episode, chart: this.props.chart })))));
    };
    Work.prototype._renderHeader = function () {
        var work = this.props.work;
        return (React.createElement("div", { className: Styles.header },
            work.imageUrl && (React.createElement("div", { className: Styles.poster },
                React.createElement("img", { className: Styles.posterImage, src: work.imageUrl }))),
            React.createElement("div", { className: work.imageUrl
                    ? Styles.headerContentWithPoster
                    : Styles.headerContent },
                React.createElement("h1", { className: Styles.title }, work.title),
                React.createElement("div", { className: Styles.stats },
                    React.createElement("span", { className: Styles.userStat },
                        React.createElement(FontAwesomeIcon, { icon: faUser }),
                        work.recordCount,
                        "\uBA85\uC774 \uAE30\uB85D \uB0A8\uAE40")),
                React.createElement(WorkStatusButton, { work: work, record: work.record, currentUser: this.props.currentUser }))));
    };
    return Work;
}(React.Component));
export { Work };
var WorkIndex = /** @class */ (function (_super) {
    __extends(WorkIndex, _super);
    function WorkIndex() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isLoading: false,
        };
        _this._loadMore = function () {
            _this.setState({ isLoading: true });
            _this.props.loadMorePosts().then(function () {
                _this.setState({ isLoading: false });
            });
        };
        return _this;
    }
    WorkIndex.prototype.render = function () {
        var _a = this.props, postConnection = _a.postConnection, excludePostID = _a.excludePostID;
        var posts = postConnection === null || postConnection === void 0 ? void 0 : postConnection.nodes;
        var hasMorePosts = postConnection === null || postConnection === void 0 ? void 0 : postConnection.hasMore;
        if (posts && excludePostID) {
            posts = posts.filter(function (post) { return post.databaseId !== excludePostID; });
        }
        return posts && posts.length > 0 ? (React.createElement("div", { className: Styles.postsSection },
            posts.map(function (post) { return (React.createElement(Post, { key: post.databaseId, post: post, showTitle: false })); }),
            hasMorePosts && (React.createElement(LoadMore, { isLoading: this.state.isLoading, onClick: this._loadMore })))) : null;
    };
    return WorkIndex;
}(React.Component));
export { WorkIndex };
export function Episodes(_a) {
    var work = _a.work, activeEpisodeNumber = _a.activeEpisodeNumber;
    var title = encodeURIComponent(work.title);
    return (React.createElement("div", { className: Styles.episodes },
        React.createElement(Link, { to: "/works/".concat(title, "/"), className: cx({
                active: !activeEpisodeNumber,
                recent: true,
            }) }, "\uCD5C\uC2E0"),
        work.episodes.map(function (ep) {
            var _a;
            return (React.createElement(Link, { to: "/works/".concat(title, "/ep/").concat(ep.number, "/"), className: cx({
                    'has-post': ((_a = ep.postCount) !== null && _a !== void 0 ? _a : 0) > 0,
                    active: ep.number === activeEpisodeNumber,
                }), key: ep.number },
                ep.number,
                "\uD654"));
        })));
}
export function EpisodeHeader(_a) {
    var _b;
    var episode = _a.episode;
    return (React.createElement("div", { className: Styles.episodeHeader },
        React.createElement("h2", { className: Styles.sectionTitle }, util.formatStatus(episode.number)),
        React.createElement("div", { className: Styles.episodeStats },
            React.createElement("span", null,
                React.createElement(FontAwesomeIcon, { icon: faComment }),
                "\uAC10\uC0C1\uD3C9 ", (_b = episode.postCount) !== null && _b !== void 0 ? _b : 0,
                "\uAC1C"),
            React.createElement("span", null,
                React.createElement(FontAwesomeIcon, { icon: faUser }),
                episode.userCount,
                "\uBA85 \uAE30\uB85D ",
                episode.suspendedUserCount != null && episode.suspendedUserCount > 0 && " (".concat(episode.suspendedUserCount, "\uBA85 \uC911\uB2E8)")))));
}
