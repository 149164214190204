var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { faCheck, faLink, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from 'react-overlays';
import { formatPeriod } from '../util';
import ModalStyles from './Modal.less';
import { Switch, SwitchItem } from './Switch';
import Styles from './TableShareDialog.less';
export function TableShareDialog(props) {
    var key = props.showAdded.toString();
    return React.createElement(TableShareDialogInternal, __assign({ key: key }, props));
}
var TableShareDialogInternal = /** @class */ (function (_super) {
    __extends(TableShareDialogInternal, _super);
    function TableShareDialogInternal() {
        var _this = this;
        var _a;
        _this = _super.apply(this, arguments) || this;
        _this.isClipboardAvailable = Boolean((_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText);
        _this.state = {
            shareContent: _this.props.showAdded ? 'added' : 'all',
            showCopied: false,
        };
        _this.copy = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        return [4 /*yield*/, navigator.clipboard.writeText(this.getShareUrl())];
                    case 1:
                        _a.sent();
                        this.setState({ showCopied: true });
                        setTimeout(function () { return _this.setState({ showCopied: false }); }, 1000);
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    TableShareDialogInternal.prototype.render = function () {
        var _this = this;
        return (React.createElement(Modal, { show: true, className: ModalStyles.container, renderBackdrop: function (props) { return React.createElement("div", __assign({ className: ModalStyles.backdrop }, props)); }, onHide: this.props.onClose },
            React.createElement("div", { className: ModalStyles.mobileBottomSheet },
                React.createElement("div", { className: ModalStyles.header },
                    React.createElement("button", { className: ModalStyles.closeButton, onClick: this.props.onClose },
                        React.createElement(FontAwesomeIcon, { icon: faTimesCircle, size: "lg" })),
                    React.createElement("h2", { className: ModalStyles.title },
                        formatPeriod(this.props.period),
                        " \uC2E0\uC791 \uACF5\uC720")),
                this.props.showAdded && (React.createElement("div", { className: Styles.contentSelector },
                    React.createElement(Switch, { value: this.state.shareContent, onChange: function (value) { return _this.setState({ shareContent: value }); }, flex: true },
                        React.createElement(SwitchItem, { value: "all" }, "\uC804\uCCB4 \uC791\uD488 \uB9AC\uC2A4\uD2B8"),
                        React.createElement(SwitchItem, { value: "added" }, "\uCD94\uAC00\uD55C \uC791\uD488 \uB9AC\uC2A4\uD2B8")))),
                React.createElement("input", { type: "text", className: Styles.urlInput, readOnly: true, value: this.getShareUrl() }),
                React.createElement("div", { className: Styles.shareButtonContainer },
                    this.isClipboardAvailable && (React.createElement("a", { href: "#", onClick: this.copy, className: Styles.copyButton }, this.state.showCopied ? React.createElement(React.Fragment, null,
                        React.createElement(FontAwesomeIcon, { icon: faCheck }),
                        "\uBCF5\uC0AC \uC644\uB8CC!") : React.createElement(React.Fragment, null,
                        React.createElement(FontAwesomeIcon, { icon: faLink }),
                        "\uB9C1\uD06C \uBCF5\uC0AC"))),
                    React.createElement("a", { href: "https://twitter.com/intent/tweet?url=".concat(this.getShareUrl()), target: "_blank", className: Styles.tweetButton },
                        React.createElement("svg", { style: { height: '1em', verticalAlign: '-0.125em' }, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" },
                            React.createElement("path", { fill: "currentColor", d: "M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" })),
                        "\uD2B8\uC717")))));
    };
    TableShareDialogInternal.prototype.getShareUrl = function () {
        if (this.state.shareContent === 'all') {
            return "https://animeta.net/table/".concat(this.props.period.period, "/");
        }
        else if (this.state.shareContent === 'added') {
            return "https://animeta.net/users/".concat(this.props.username, "/table/").concat(this.props.period.period, "/");
        }
        throw new Error('unreachable');
    };
    return TableShareDialogInternal;
}(React.Component));
