import * as React from 'react';
import { Link } from 'nuri';
import Styles from './UserLayout.less';
import * as Grid from './Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
export default function UserLayout(props) {
    var user = props.data.user;
    var basePath = "/users/".concat(encodeURIComponent(user.name), "/");
    var joinedDate = new Date(user.joinedAt);
    return (React.createElement("div", null,
        React.createElement("div", { className: Styles.header },
            React.createElement(Grid.Row, null,
                React.createElement(Grid.Column, { size: 3, pull: "left" },
                    React.createElement("div", { className: Styles.profile },
                        React.createElement("h1", { className: Styles.title },
                            React.createElement(Link, { to: basePath }, user.name)),
                        React.createElement("div", { className: Styles.dateJoined },
                            joinedDate.getFullYear(),
                            ". ",
                            joinedDate.getMonth() + 1,
                            ".",
                            ' ',
                            joinedDate.getDate(),
                            " \uAC00\uC785"))),
                React.createElement(Grid.Column, { size: 9, pull: "left", className: Styles.nav },
                    React.createElement(Link, { to: basePath, className: Styles.navItem },
                        "\uC791\uD488",
                        ' ',
                        React.createElement("span", { className: Styles.navItemCount }, user.recordCount)),
                    React.createElement(Link, { to: "".concat(basePath, "history/"), className: Styles.navItem },
                        "\uAE30\uB85D",
                        ' ',
                        React.createElement("span", { className: Styles.navItemCount }, user.postCount)),
                    React.createElement("div", { style: { flex: 1 } }),
                    user.isCurrentUser && (React.createElement(Link, { to: "/settings/", className: Styles.settingsNavItem },
                        React.createElement(FontAwesomeIcon, { icon: faCog }),
                        "\uC124\uC815"))))),
        props.noContentWrapper ? props.children : React.createElement("div", { className: Styles.content }, props.children)));
}
