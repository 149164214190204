import React from "react";
import { CenteredFullWidth } from "../ui/Layout";
export var errorHandler = {
    component: function (_a) {
        var error = _a.error;
        return (React.createElement(CenteredFullWidth, null,
            React.createElement("h1", null, "\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4."),
            React.createElement("p", null, "\uD398\uC774\uC9C0 \uC0C8\uB85C \uACE0\uCE68 \uD6C4 \uB2E4\uC2DC \uC2DC\uB3C4\uD574\uBCF4\uC138\uC694."),
            React.createElement("pre", null, error.toString())));
    },
};
