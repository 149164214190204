var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Post_PostFragmentDoc } from '../../ui/__generated__/Post.graphql';
import { PostComment_PostFragmentDoc } from '../../ui/__generated__/GqlPostComment.graphql';
export var UserHistoryRoute_PostConnectionFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "UserHistoryRoute_postConnection" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "PostConnection" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "nodes" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }, { "kind": "FragmentSpread", "name": { "kind": "Name", "value": "Post_post" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "hasMore" } }] } }] };
export var UserHistoryRouteDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "UserHistoryRoute" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "username" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "String" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "alias": { "kind": "Name", "value": "user" }, "name": { "kind": "Name", "value": "userByName" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "name" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "username" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "name" } }, { "kind": "Field", "name": { "kind": "Name", "value": "posts" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "count" }, "value": { "kind": "IntValue", "value": "32" } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "UserHistoryRoute_postConnection" } }] } }] } }] } }], UserHistoryRoute_PostConnectionFragmentDoc.definitions, true), Post_PostFragmentDoc.definitions, true), PostComment_PostFragmentDoc.definitions, true) };
export var UserHistoryRoute_MorePostsDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "UserHistoryRoute_morePosts" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "beforeId" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "user" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "id" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "posts" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "beforeId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "beforeId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "count" }, "value": { "kind": "IntValue", "value": "32" } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "UserHistoryRoute_postConnection" } }] } }] } }] } }], UserHistoryRoute_PostConnectionFragmentDoc.definitions, true), Post_PostFragmentDoc.definitions, true), PostComment_PostFragmentDoc.definitions, true) };
