var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef } from 'react';
import * as Layout from '../ui/Layout';
import * as Grid from '../ui/Grid';
import Styles from '../../less/table-period.less';
import { TableItem } from '../ui/TableItem';
import { formatPeriod } from '../util';
import { Link } from 'nuri';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { UserTableRouteDocument } from './__generated__/UserTable.graphql';
import useIntersectionObserver from '../ui/useIntersectionObserver';
import { UserLayout } from '../layouts/UserLayout';
var UserTable = function (_a) {
    var data = _a.data;
    var sentinelEl = useRef(null);
    var entry = useIntersectionObserver(sentinelEl, {
        threshold: [0],
        rootMargin: '-48px 0px 0px 0px',
    });
    var isHeaderStuck = entry ? !entry.isIntersecting : false;
    var tablePeriod = data.tablePeriod;
    var items = tablePeriod.items;
    return (React.createElement("div", { className: Styles.container },
        React.createElement("div", { ref: sentinelEl }),
        React.createElement(Layout.CenteredFullWidth, { className: isHeaderStuck ? Styles.stuckHeaderContainer : Styles.headerContainer },
            React.createElement("div", { className: Styles.userHeader },
                React.createElement("div", { className: Styles.userPageTitle },
                    formatPeriod(tablePeriod),
                    " \uC2E0\uC791"),
                React.createElement(Link, { to: "/table/".concat(tablePeriod.period, "/"), className: Styles.fullTableLink },
                    "\uC804\uCCB4 \uC791\uD488 \uBCF4\uAE30 ",
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight })))),
        items.length > 0 ? (React.createElement(Grid.Row, { className: Styles.items }, items.map(function (item, i) { return (React.createElement(React.Fragment, null,
            React.createElement(Grid.Column, { size: 6, midSize: 12, pull: "left" },
                React.createElement(TableItem, { key: item.work.databaseId, item: item, onAddRecord: function () { } })),
            i % 2 === 1 && React.createElement("div", { style: { clear: 'both' } }))); }))) : (React.createElement(Grid.Row, { className: Styles.itemsEmpty },
            React.createElement("p", null, "\uCD94\uAC00\uD55C \uC791\uD488\uC774 \uC5C6\uC2B5\uB2C8\uB2E4."),
            React.createElement("p", null,
                React.createElement(Link, { to: "/table/".concat(tablePeriod.period, "/"), className: Styles.fullTableLink },
                    "\uC804\uCCB4 \uC791\uD488 \uBCF4\uAE30 ",
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight })))))));
};
var routeHandler = UserLayout({ noContentWrapper: true }, { noHero: true, noNotice: true }).wrap({
    component: UserTable,
    load: function (_a) {
        var loader = _a.loader, params = _a.params, notFound = _a.notFound;
        return __awaiter(this, void 0, void 0, function () {
            var username, period, _b, user, tablePeriod, data;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        username = params.username, period = params.period;
                        return [4 /*yield*/, loader.graphql(UserTableRouteDocument, {
                                period: period,
                                username: username,
                                withRecommendations: true,
                            })];
                    case 1:
                        _b = _c.sent(), user = _b.user, tablePeriod = _b.tablePeriod, data = __rest(_b, ["user", "tablePeriod"]);
                        if (!tablePeriod)
                            return [2 /*return*/, notFound()];
                        if (!user)
                            return [2 /*return*/, notFound()];
                        return [2 /*return*/, __assign(__assign({}, data), { tablePeriod: tablePeriod, user: user })];
                }
            });
        });
    },
    renderTitle: function (_a, parentTitle) {
        var tablePeriod = _a.tablePeriod;
        return "".concat(formatPeriod(tablePeriod), " \uC2E0\uC791 - ").concat(parentTitle);
    },
    renderMeta: function (_a) {
        var user = _a.user, tablePeriod = _a.tablePeriod;
        var period = tablePeriod.period;
        return {
            og_url: "/users/".concat(user.name, "/table/").concat(period, "/"),
            tw_url: "/users/".concat(user.name, "/table/").concat(period, "/"),
            description: "".concat(user.name, "\uB2D8\uC758 \uAD00\uC2EC \uC791\uD488\uC744 \uD655\uC778\uD574 \uBCF4\uC138\uC694."),
            og_image_static: "share-table-q".concat(period.split('Q')[1], ".jpg"),
            tw_card_type: 'summary_large_image',
            tw_image_static: "share-table-q".concat(period.split('Q')[1], ".jpg"),
        };
    },
});
export default routeHandler;
