var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Library_UserFragmentDoc, Library_RecordFragmentDoc } from '../../ui/__generated__/Library.graphql';
import { LibraryFilter_CategoryFragmentDoc, LibraryFilter_RecordFiltersFragmentDoc, LibraryFilter_RecordFilterFragmentDoc } from '../../ui/__generated__/LibraryFilter.graphql';
export var UserRouteDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "UserRoute" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "username" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "String" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "statusTypeFilter" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "StatusType" } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "categoryIdFilter" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "recordOrder" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "RecordOrder" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "alias": { "kind": "Name", "value": "user" }, "name": { "kind": "Name", "value": "userByName" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "name" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "username" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "Library_user" } }] } }] } }], Library_UserFragmentDoc.definitions, true), LibraryFilter_CategoryFragmentDoc.definitions, true), LibraryFilter_RecordFiltersFragmentDoc.definitions, true), LibraryFilter_RecordFilterFragmentDoc.definitions, true), Library_RecordFragmentDoc.definitions, true) };
