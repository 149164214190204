var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import cx from 'classnames';
var Sortable = /** @class */ (function (_super) {
    __extends(Sortable, _super);
    function Sortable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._onMoveUp = function (i) {
            if (i > 0) {
                _this.props.onSwap(i, i - 1);
            }
        };
        _this._onMoveDown = function (i) {
            if (i < React.Children.count(_this.props.children) - 1) {
                _this.props.onSwap(i, i + 1);
            }
        };
        return _this;
    }
    Sortable.prototype.render = function () {
        var _this = this;
        var items = React.Children.map(this.props.children, function (child, i) { return (React.createElement(SortableItem, { component: child, key: typeof child === 'object' && child && 'key' in child ? child.key : i, order: i, onMoveUp: _this._onMoveUp, onMoveDown: _this._onMoveDown, onDrop: _this.props.onDrop })); });
        return React.createElement("div", null, items);
    };
    return Sortable;
}(React.Component));
export { Sortable };
var SortableItem = /** @class */ (function (_super) {
    __extends(SortableItem, _super);
    function SortableItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._registeredEventListeners = false;
        _this.state = {
            dragging: false,
            origTop: 0,
            origBottom: 0,
            holdY: 0,
            mouseY: 0,
        };
        _this._manageEventListeners = function (isDragging) {
            var body = findDOMNode(_this).ownerDocument;
            if (isDragging && !_this._registeredEventListeners) {
                body.addEventListener('mousemove', _this._onMouseMove);
                body.addEventListener('mouseup', _this._onMouseUp);
                _this._registeredEventListeners = true;
            }
            else if (!isDragging && _this._registeredEventListeners) {
                body.removeEventListener('mousemove', _this._onMouseMove);
                body.removeEventListener('mouseup', _this._onMouseUp);
                _this._registeredEventListeners = false;
            }
        };
        _this._onMouseDown = function (event) {
            var bounds = findDOMNode(_this).getBoundingClientRect();
            _this.setState({
                dragging: true,
                origTop: bounds.top,
                origBottom: bounds.bottom,
                holdY: event.clientY - bounds.top,
                mouseY: event.clientY,
            });
        };
        _this._onMouseUp = function () {
            _this.setState({ dragging: false });
            if (_this.props.onDrop) {
                _this.props.onDrop();
            }
        };
        _this._onMouseMove = function (event) {
            if (!_this.state.dragging) {
                return;
            }
            var mouseY = event.clientY;
            var offset = mouseY - _this.state.origTop - _this.state.holdY;
            var currentBottom = _this.state.origBottom + offset;
            if (currentBottom < _this.state.origTop) {
                _this.props.onMoveUp(_this.props.order);
            }
            var currentTop = _this.state.origTop + offset;
            if (_this.state.origBottom < currentTop) {
                _this.props.onMoveDown(_this.props.order);
            }
            _this.setState({ mouseY: mouseY });
        };
        _this._remeasure = function () {
            var offset = _this.state.mouseY - _this.state.origTop - _this.state.holdY;
            var bounds = findDOMNode(_this).getBoundingClientRect();
            _this.setState({
                origTop: bounds.top - offset,
                origBottom: bounds.bottom - offset,
            });
        };
        return _this;
    }
    SortableItem.prototype.componentDidUpdate = function (prevProps) {
        if (this.state.dragging && this.props.order != prevProps.order) {
            this._remeasure();
        }
        this._manageEventListeners(this.state.dragging);
    };
    SortableItem.prototype.componentWillUnmount = function () {
        this._manageEventListeners(false);
    };
    SortableItem.prototype.render = function () {
        var style = {};
        if (this.state.dragging) {
            style.position = 'relative';
            style.top = this.state.mouseY - this.state.origTop - this.state.holdY;
        }
        return (React.createElement("div", { className: cx({
                draggable: true,
                dragging: this.state.dragging,
            }), style: style, onMouseDown: this._onMouseDown }, this.props.component));
    };
    return SortableItem;
}(React.Component));
