var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from 'react';
import sortBy from 'lodash/sortBy';
import some from 'lodash/some';
import { Link } from 'nuri';
import Styles from '../../less/table-period.less';
import { Switch, SwitchItem } from '../ui/Switch';
import * as Layout from '../ui/Layout';
import * as Grid from '../ui/Grid';
import LoginDialog from '../ui/LoginDialog';
import SearchInput from '../ui/SearchInput';
import { AppLayout } from '../layouts/AppLayout';
import { Popover } from '../ui/Popover';
import { TableShareDialog } from '../ui/TableShareDialog';
import { TableItem } from '../ui/TableItem';
import { formatPeriod } from '../util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { TableRouteDocument } from './__generated__/Table.graphql';
import useIntersectionObserver from '../ui/useIntersectionObserver';
import groupBy from 'lodash/groupBy';
function PageTitle(props) {
    var activePeriod = props.period;
    var periodsByYear = Object.entries(groupBy(props.tablePeriods, 'year'));
    periodsByYear.sort(function (_a, _b) {
        var a = _a[0];
        var b = _b[0];
        return Number(b) - Number(a);
    });
    periodsByYear.forEach(function (_a) {
        var periods = _a[1];
        return periods.sort(function (a, b) { return a.month - b.month; });
    });
    return (React.createElement(Popover, { relativeContainer: false, contentClassName: Styles.nav, renderTrigger: function (_a) {
            var toggle = _a.toggle;
            return (React.createElement("a", { href: "#", className: Styles.pageTitle, onClick: toggle },
                formatPeriod(activePeriod),
                " \uC2E0\uC791",
                React.createElement(FontAwesomeIcon, { icon: faCaretDown, size: "sm" })));
        } }, periodsByYear.map(function (_a) {
        var year = _a[0], periods = _a[1];
        // fillers for mobile view
        var fillers = [];
        for (var i = 0; i < 4 - periods.length; i++) {
            fillers.push(React.createElement("span", { className: Styles.navPeriodHidden }));
        }
        return (React.createElement("div", { className: Styles.navRow },
            React.createElement("div", { className: Styles.navYear },
                year,
                "\uB144"),
            periods.map(function (_a) {
                var period = _a.period, month = _a.month;
                return (React.createElement(Link, { to: "/table/".concat(period, "/"), className: period === activePeriod.period ? Styles.navPeriodActive : Styles.navPeriodNormal },
                    month,
                    "\uC6D4"));
            }),
            fillers));
    })));
}
var ShareButton = /** @class */ (function (_super) {
    __extends(ShareButton, _super);
    function ShareButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isOpen: false,
            hidePopover: false,
        };
        _this.open = function (e) {
            e.preventDefault();
            _this.setState({ isOpen: true, hidePopover: true });
        };
        _this.close = function () { return _this.setState({ isOpen: false }); };
        _this.hidePopover = function () { return _this.setState({ hidePopover: true }); };
        return _this;
    }
    ShareButton.prototype.render = function () {
        return React.createElement("div", { className: Styles.shareButtonContainer },
            React.createElement("a", { href: "#share", className: Styles.shareButton, onClick: this.open },
                React.createElement(FontAwesomeIcon, { icon: faShareSquare }),
                "\uACF5\uC720"),
            this.props.showPopoverOnce && !this.state.hidePopover && (React.createElement("span", { className: Styles.shareButtonPopover, onClick: this.hidePopover }, "\uCD94\uAC00\uD55C \uC791\uD488 \uB9AC\uC2A4\uD2B8\uB97C \uACF5\uC720\uD574 \uBCF4\uC138\uC694.")),
            this.state.isOpen && (React.createElement(TableShareDialog, { period: this.props.period, username: this.props.username, onClose: this.close, showAdded: this.props.showAdded })));
    };
    return ShareButton;
}(React.Component));
function Header(_a) {
    var excludeKR = _a.excludeKR, showAddedOnlyFilter = _a.showAddedOnlyFilter, filter = _a.filter, onFilterChange = _a.onFilterChange, period = _a.period, tablePeriods = _a.tablePeriods, currentUser = _a.currentUser, totalCount = _a.totalCount, addedCount = _a.addedCount, showShareButtonPopoverOnce = _a.showShareButtonPopoverOnce;
    var options;
    if (!excludeKR) {
        options = [
            { value: 'schedule', label: '날짜 (日)' },
            { value: 'schedule.kr', label: '날짜 (韓)' },
            { value: 'recordCount', label: '인기' },
        ];
    }
    else {
        options = [
            { value: 'schedule', label: '날짜' },
            { value: 'recordCount', label: '인기' },
        ];
    }
    if (period.isRecommendationEnabled) {
        options.unshift({
            value: 'recommended',
            label: '추천',
            onClick: currentUser ? undefined : function () { LoginDialog.open(); return false; },
        });
    }
    return (React.createElement("div", { className: Styles.header },
        React.createElement("div", { className: Styles.pageTitleAndShareContainer },
            React.createElement(PageTitle, { period: period, tablePeriods: tablePeriods }),
            React.createElement(ShareButton, { period: period, username: currentUser && currentUser.name, showAdded: showAddedOnlyFilter, showPopoverOnce: showShareButtonPopoverOnce })),
        React.createElement("div", { className: Styles.settings },
            React.createElement("div", { className: Styles.settingsItem },
                '정렬: ',
                React.createElement(Switch, { value: filter.sort, onChange: function (newSort) { return onFilterChange(__assign(__assign({}, filter), { sort: newSort })); }, minimal: true }, options.map(function (option) { return (React.createElement(SwitchItem, { key: option.value, value: option.value, onClick: option.onClick }, option.label)); }))),
            showAddedOnlyFilter && (React.createElement("div", { className: Styles.settingsItem },
                React.createElement(Switch, { value: filter.addedOnly, onChange: function (addedOnly) { return onFilterChange(__assign(__assign({}, filter), { addedOnly: addedOnly })); }, minimal: true },
                    React.createElement(SwitchItem, { value: false },
                        "\uC804\uCCB4 (",
                        totalCount,
                        ")"),
                    React.createElement(SwitchItem, { value: true },
                        "\uCD94\uAC00\uD55C \uC791\uD488 (",
                        addedCount,
                        ")")))))));
}
var scheduleComparator = function (item) { var _a, _b, _c; return nullslast((_c = (_b = (_a = item.work.metadata) === null || _a === void 0 ? void 0 : _a.schedules) === null || _b === void 0 ? void 0 : _b.find(function (it) { return it.country === 'jp'; })) === null || _c === void 0 ? void 0 : _c.date); };
var preferKRScheduleComparator = function (item) {
    var _a, _b, _c, _d, _e, _f;
    return nullslast(((_c = (_b = (_a = item.work.metadata) === null || _a === void 0 ? void 0 : _a.schedules) === null || _b === void 0 ? void 0 : _b.find(function (it) { return it.country === 'kr'; })) === null || _c === void 0 ? void 0 : _c.date) ||
        ((_f = (_e = (_d = item.work.metadata) === null || _d === void 0 ? void 0 : _d.schedules) === null || _e === void 0 ? void 0 : _e.find(function (it) { return it.country === 'jp'; })) === null || _f === void 0 ? void 0 : _f.date));
};
var recordCountComparator = function (item) { var _a; return -((_a = item.work.recordCount) !== null && _a !== void 0 ? _a : 0); };
var recommendedComparator = function (item) { return -(item.recommendationScore || 0); };
var comparatorMap = {
    recommended: recommendedComparator,
    schedule: scheduleComparator,
    'schedule.kr': preferKRScheduleComparator,
    recordCount: recordCountComparator,
};
function nullslast(val) {
    return [!val, val];
}
var Table = function (_a) {
    var data = _a.data, writeData = _a.writeData;
    var sentinelEl = useRef(null);
    var entry = useIntersectionObserver(sentinelEl, {
        threshold: [0],
        rootMargin: '-48px 0px 0px 0px',
    });
    var isHeaderStuck = entry ? !entry.isIntersecting : false;
    var _b = useState(false), showShareButtonPopoverOnce = _b[0], setShowShareButtonPopoverOnce = _b[1];
    // scroll to top when filter changes
    useEffect(function () {
        window.scrollTo(0, 0);
    }, [data.filter]);
    var filter = data.filter, containsKRSchedule = data.containsKRSchedule, hasAnyRecord = data.hasAnyRecord, items = data.items, currentUser = data.currentUser, tablePeriod = data.tablePeriod;
    var filteredItems = items;
    if (filter.addedOnly) {
        filteredItems = filteredItems.filter(function (it) { return it.record != null; });
    }
    var onFilterChange = function (newFilter) {
        writeData(function (data) {
            data.filter = newFilter;
            data.items = sortBy(data.items, comparatorMap[newFilter.sort]);
        });
    };
    var recordAdded = function (item, record) {
        writeData(function (data) {
            var _a;
            item.record = record;
            item.work.recordCount = ((_a = item.work.recordCount) !== null && _a !== void 0 ? _a : 0) + 1;
            data.hasAnyRecord = true;
        });
        // setShowShareButtonPopoverOnce(true) // XXX
    };
    return (React.createElement("div", { className: Styles.container },
        React.createElement(Layout.CenteredFullWidth, null,
            React.createElement("div", { className: Styles.search },
                React.createElement(SearchInput, null))),
        React.createElement("div", { ref: sentinelEl }),
        React.createElement(Layout.CenteredFullWidth, { className: isHeaderStuck ? Styles.stuckHeaderContainer : Styles.headerContainer },
            React.createElement(Header, { period: tablePeriod, tablePeriods: data.tablePeriods, filter: filter, excludeKR: !containsKRSchedule, showAddedOnlyFilter: hasAnyRecord, onFilterChange: onFilterChange, currentUser: currentUser, totalCount: items.length, addedCount: items.reduce(function (count, it) { return count + (it.record != null ? 1 : 0); }, 0), showShareButtonPopoverOnce: showShareButtonPopoverOnce })),
        React.createElement(Grid.Row, { className: Styles.items }, filteredItems.map(function (item, i) { return (React.createElement(React.Fragment, null,
            React.createElement(Grid.Column, { size: 6, midSize: 12, pull: "left" },
                React.createElement(TableItem, { key: item.work.databaseId, item: item, onAddRecord: recordAdded })),
            i % 2 === 1 && React.createElement("div", { style: { clear: 'both' } }))); }))));
};
var routeHandler = AppLayout({ activeMenu: 'search' }).wrap({
    component: Table,
    load: function (_a) {
        var params = _a.params, loader = _a.loader;
        return __awaiter(this, void 0, void 0, function () {
            var period, data, tablePeriod, sort, items;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        period = params.period;
                        return [4 /*yield*/, loader.graphql(TableRouteDocument, { period: period, withRecommendations: true })];
                    case 1:
                        data = _b.sent();
                        tablePeriod = data.tablePeriod;
                        if (!tablePeriod)
                            throw new Error('not found');
                        sort = data.currentUser && tablePeriod.isRecommendationEnabled ? 'recommended' :
                            period === tablePeriod.isCurrent ? 'schedule' :
                                'recordCount';
                        items = tablePeriod.items;
                        return [2 /*return*/, __assign(__assign({}, data), { tablePeriod: tablePeriod, items: sortBy(items, comparatorMap[sort]), containsKRSchedule: some(items, function (i) { var _a, _b, _c; return (_c = (_b = (_a = i.work.metadata) === null || _a === void 0 ? void 0 : _a.schedules) === null || _b === void 0 ? void 0 : _b.find(function (it) { return it.country === 'kr'; })) === null || _c === void 0 ? void 0 : _c.date; }), hasAnyRecord: some(items, function (i) { return i.record; }), filter: {
                                    sort: sort,
                                    addedOnly: false,
                                } })];
                }
            });
        });
    },
    renderTitle: function (_a) {
        var tablePeriod = _a.tablePeriod;
        return "".concat(formatPeriod(tablePeriod), " \uC2E0\uC791");
    },
    renderMeta: function (_a) {
        var tablePeriod = _a.tablePeriod;
        var period = tablePeriod.period;
        return {
            og_url: "/table/".concat(period, "/"),
            tw_url: "/table/".concat(period, "/"),
            og_image_static: "share-table-q".concat(period.split('Q')[1], ".jpg"),
            tw_card_type: 'summary_large_image',
            tw_image_static: "share-table-q".concat(period.split('Q')[1], ".jpg"),
        };
    },
});
export default routeHandler;
