var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { WorkViewsFragmentDoc, WorkViews_PostConnectionFragmentDoc } from '../../ui/__generated__/WorkViews.graphql';
import { WorkStatusButton_WorkFragmentDoc, WorkStatusButton_RecordFragmentDoc } from '../../ui/__generated__/WorkStatusButton.graphql';
import { Post_PostFragmentDoc } from '../../ui/__generated__/Post.graphql';
import { PostComment_PostFragmentDoc } from '../../ui/__generated__/GqlPostComment.graphql';
import { WeeklyChartFragmentDoc } from '../../ui/__generated__/WeeklyChart.graphql';
export var WorkRouteDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "WorkRoute" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "title" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "String" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "currentUser" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }] } }, { "kind": "Field", "alias": { "kind": "Name", "value": "work" }, "name": { "kind": "Name", "value": "workByTitle" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "title" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "title" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "WorkViews" } }, { "kind": "Field", "name": { "kind": "Name", "value": "posts" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "count" }, "value": { "kind": "IntValue", "value": "10" } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "WorkViews_postConnection" } }] } }] } }, { "kind": "FragmentSpread", "name": { "kind": "Name", "value": "WeeklyChart" } }] } }], WorkViewsFragmentDoc.definitions, true), WorkStatusButton_WorkFragmentDoc.definitions, true), WorkStatusButton_RecordFragmentDoc.definitions, true), WorkViews_PostConnectionFragmentDoc.definitions, true), Post_PostFragmentDoc.definitions, true), PostComment_PostFragmentDoc.definitions, true), WeeklyChartFragmentDoc.definitions, true) };
export var WorkRoute_MorePostsDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "WorkRoute_morePosts" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "workId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "beforeId" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "work" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "id" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "workId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "posts" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "beforeId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "beforeId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "count" }, "value": { "kind": "IntValue", "value": "10" } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "WorkViews_postConnection" } }] } }] } }] } }], WorkViews_PostConnectionFragmentDoc.definitions, true), Post_PostFragmentDoc.definitions, true), PostComment_PostFragmentDoc.definitions, true) };
export var WorkRoute_RefetchDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "WorkRoute_refetch" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "id" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "work" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "id" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "id" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "WorkViews" } }, { "kind": "Field", "name": { "kind": "Name", "value": "posts" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "count" }, "value": { "kind": "IntValue", "value": "10" } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "WorkViews_postConnection" } }] } }] } }] } }], WorkViewsFragmentDoc.definitions, true), WorkStatusButton_WorkFragmentDoc.definitions, true), WorkStatusButton_RecordFragmentDoc.definitions, true), WorkViews_PostConnectionFragmentDoc.definitions, true), Post_PostFragmentDoc.definitions, true), PostComment_PostFragmentDoc.definitions, true) };
