var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import Styles from './Popover.less';
var PopoverContent = /** @class */ (function (_super) {
    __extends(PopoverContent, _super);
    function PopoverContent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PopoverContent.prototype.componentDidMount = function () {
        document.addEventListener('click', this.props.onClose, false);
    };
    PopoverContent.prototype.componentWillUnmount = function () {
        document.removeEventListener('click', this.props.onClose, false);
    };
    PopoverContent.prototype.render = function () {
        return (React.createElement("div", { className: "".concat(Styles.popover, " ").concat(this.props.className || '') }, this.props.children));
    };
    return PopoverContent;
}(React.Component));
var Popover = /** @class */ (function (_super) {
    __extends(Popover, _super);
    function Popover() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            show: false,
        };
        _this._toggle = function (event) {
            event.preventDefault();
            event.stopPropagation(); // prevent PopoverContent click handler trigger
            _this.setState({ show: !_this.state.show });
        };
        _this._close = function () {
            _this.setState({ show: false });
        };
        return _this;
    }
    Popover.prototype.render = function () {
        var _a = this.props, containerClassName = _a.containerClassName, _b = _a.relativeContainer, relativeContainer = _b === void 0 ? true : _b, contentClassName = _a.contentClassName, renderTrigger = _a.renderTrigger, children = _a.children;
        var show = this.state.show;
        return (React.createElement("div", { className: containerClassName, style: relativeContainer ? { position: 'relative' } : undefined },
            renderTrigger({ toggle: this._toggle }),
            show && React.createElement(PopoverContent, { className: contentClassName, onClose: this._close }, children)));
    };
    return Popover;
}(React.Component));
export { Popover };
