var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState } from "react";
import { CurrentUserLayout } from "../layouts/CurrentUserLayout";
import Styles from "./ManageRating.module.less";
import * as Grid from "../ui/Grid";
import { Rating } from "../ui/Rating";
import chunk from "lodash/chunk";
import { AutoLoadMore } from "../ui/LoadMore";
import { graphql } from "../API";
import { Link } from "nuri";
import useIntersectionObserver from "../ui/useIntersectionObserver";
import { ManageRatingRoute_UpdateRatingDocument } from "./__generated__/ManageRating.graphql";
var RatingEntry = function (_a) {
    var record = _a.record, onRatingUpdate = _a.onRatingUpdate;
    var _b = useState(false), isSaving = _b[0], setIsSaving = _b[1];
    var updateRating = function (_, rating) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSaving(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, graphql(ManageRatingRoute_UpdateRatingDocument, {
                            input: {
                                recordId: record.id,
                                rating: rating,
                            }
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setIsSaving(false);
                    return [7 /*endfinally*/];
                case 4:
                    onRatingUpdate(record.id, rating);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: Styles.rateEntry },
        React.createElement(Link, { to: "/records/".concat(record.id, "/"), className: Styles.rateEntryTitle }, record.title),
        React.createElement("div", { className: Styles.rateEntryRating },
            React.createElement(Rating, { defaultValue: record.rating, onChange: updateRating, disabled: isSaving }))));
};
var ManageRating = function (_a) {
    var data = _a.data, loader = _a.loader, writeData = _a.writeData;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var sentinelEl = useRef(null);
    var entry = useIntersectionObserver(sentinelEl, {
        threshold: [0],
        rootMargin: "-".concat(48 + 16, "px 0px 0px 0px"),
    });
    var isHeaderStuck = entry ? !entry.isIntersecting : false;
    var rawRatingSummaries = data.ratingSummaries, unratedRecordCount = data.unratedRecordCount, unratedRecords = data.unratedRecords;
    var ratingSummaries = [5, 4, 3, 2, 1, 0].map(function (rating) {
        return rawRatingSummaries.filter(function (it) { return Math.floor(it.rating) === rating; })
            .reduce(function (acc, it) { return ({
            rating: rating,
            recordCount: acc.recordCount + it.recordCount,
        }); }, {
            rating: rating,
            recordCount: 0,
        });
    });
    var ratedCount = ratingSummaries.reduce(function (acc, it) { return acc + it.recordCount; }, 0);
    var totalCount = ratedCount + unratedRecordCount;
    var barMaxValue = ratingSummaries.reduce(function (acc, it) { return Math.max(acc, it.recordCount); }, 0);
    var loadMore = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, loader.v5.call("/api/v5/ManageRating/getUnratedRecords", { cursor: unratedRecords.nextCursor })];
                case 2:
                    result_1 = _a.sent();
                    writeData(function (data) {
                        data.unratedRecords.data = data.unratedRecords.data.concat(result_1.data);
                        data.unratedRecords.nextCursor = result_1.nextCursor;
                    });
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onRatingUpdate = function (recordId, rating) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    writeData(function (data) {
                        var record = data.unratedRecords.data.find(function (it) { return it.id === recordId; });
                        if (record) {
                            if (rating) {
                                record.rating = rating;
                            }
                            else {
                                delete record.rating;
                            }
                        }
                    });
                    return [4 /*yield*/, loader.v5.call("/api/v5/ManageRating/", {})];
                case 1:
                    result = _a.sent();
                    writeData(function (data) {
                        Object.assign(data, result);
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Grid.Row, null,
        React.createElement("div", { ref: sentinelEl }),
        React.createElement(Grid.Column, { size: 4, pull: "right", className: isHeaderStuck ? Styles.stuckSidebar : Styles.sidebar },
            React.createElement("div", { className: Styles.progress },
                React.createElement("strong", null,
                    Math.floor((ratedCount / totalCount) * 100),
                    "% \uC644\uB8CC"),
                " (",
                ratedCount,
                "/",
                totalCount,
                ")"),
            React.createElement("table", { className: Styles.statsTable },
                React.createElement("tbody", null, ratingSummaries.map(function (it) { return (React.createElement("tr", { key: "rating-".concat(it.rating) },
                    React.createElement("th", { className: Styles.ratingCell }, it.rating === 5 ? '5점' : it.rating === 0 ? '0.5점' : "".concat(it.rating, "\uC810\uB300")),
                    React.createElement("td", null,
                        React.createElement("div", { className: Styles.bar },
                            React.createElement("div", { className: Styles.barInner, style: {
                                    width: "".concat(barMaxValue > 0
                                        ? (it.recordCount / barMaxValue) * 100
                                        : 0, "%"),
                                } }))),
                    React.createElement("td", { className: Styles.countCell }, it.recordCount))); })))),
        React.createElement(Grid.Column, { size: 8, pull: "left" },
            unratedRecords.data.length > 0 ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: Styles.title }, "\uBCC4\uC810\uC744 \uB9E4\uACA8\uBCF4\uC138\uC694 \uD83E\uDD29"),
                React.createElement("div", { className: Styles.help }, "\uC544\uC9C1 \uBCC4\uC810\uC774 \uC5C6\uB294 \uAC10\uC0C1 \uC644\uB8CC \uC791\uD488\uC774 \uD45C\uC2DC\uB429\uB2C8\uB2E4."))) : (React.createElement("div", { className: Styles.empty },
                React.createElement("h1", null, "\uBCC4\uC810\uC744 \uBAA8\uB450 \uB9E4\uACBC\uC2B5\uB2C8\uB2E4 \uD83E\uDD29"),
                React.createElement("p", null, "\uAC1C\uBCC4 \uC791\uD488 \uAE30\uB85D\uC5D0\uC11C \uC5B8\uC81C\uB4E0\uC9C0 \uBCC4\uC810\uC744 \uC218\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4."))),
            chunk(unratedRecords.data, 2).map(function (records, j) { return (React.createElement("div", { key: j, className: Styles.rateEntryRow },
                records.map(function (record) { return (React.createElement(RatingEntry, { key: "record-".concat(record.id), record: record, onRatingUpdate: onRatingUpdate })); }),
                records.length === 1 && React.createElement("div", { className: Styles.rateEntryFiller }))); }),
            unratedRecords.nextCursor && (React.createElement("div", { className: Styles.loadMore },
                React.createElement(AutoLoadMore, { isLoading: isLoading, onClick: loadMore })))),
        React.createElement("div", { style: { clear: "both" } })));
};
var routeHandler = CurrentUserLayout.wrap({
    component: ManageRating,
    load: function (_a) {
        var loader = _a.loader;
        return __awaiter(this, void 0, void 0, function () {
            var _b, data, unratedRecords;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            loader.v5.call("/api/v5/ManageRating/", {}),
                            loader.v5.call("/api/v5/ManageRating/getUnratedRecords", {
                                cursor: null,
                            }),
                        ])];
                    case 1:
                        _b = _c.sent(), data = _b[0], unratedRecords = _b[1];
                        return [2 /*return*/, __assign(__assign({}, data), { unratedRecords: unratedRecords })];
                }
            });
        });
    },
});
export default routeHandler;
