var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { AppLayout } from '../layouts/AppLayout';
import { createFrontendSession } from '../API';
import { trackEvent } from '../Tracking';
import Styles from './Signup.module.less';
import { API } from '../ApiClient';
var Signup = /** @class */ (function (_super) {
    __extends(Signup, _super);
    function Signup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            submitted: false,
            username: '',
            password: '',
            passwordCheck: '',
        };
        _this.onSubmit = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var invalid, result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        invalid = this.validate();
                        if (invalid) {
                            alert(invalid);
                            return [2 /*return*/];
                        }
                        this.setState({ submitted: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, API.call('/api/v5/Signup/createAccount', {
                                username: this.state.username,
                                password1: this.state.password,
                                password2: this.state.passwordCheck,
                            })];
                    case 2:
                        result = _a.sent();
                        this.handleSuccess(result);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.setState({ submitted: false });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleSuccess = function (result) { return __awaiter(_this, void 0, void 0, function () {
            var basePath, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        basePath = "/users/".concat(encodeURIComponent(this.state.username), "/");
                        trackEvent({
                            eventCategory: 'User',
                            eventAction: 'SignUp',
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, createFrontendSession({ authResult: result.authResult })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.props.controller.load({
                            path: basePath,
                            query: {},
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    Signup.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: Styles.container, onSubmit: this.onSubmit },
            React.createElement("div", { className: Styles.header },
                React.createElement("h2", { className: Styles.title }, "\uD68C\uC6D0 \uAC00\uC785")),
            React.createElement("form", { method: "post", action: "/signup/" },
                React.createElement("div", { className: Styles.row },
                    React.createElement("label", null, "\uC544\uC774\uB514"),
                    React.createElement("input", { name: "username", maxLength: 30, autoFocus: true, value: this.state.username, onChange: function (e) { return _this.setState({ username: e.target.value }); } }),
                    React.createElement("div", { className: Styles.hint }, "\uC54C\uD30C\uBCB3, \uC22B\uC790, '_' \uB9CC \uAC00\uB2A5. \uCD5C\uB300 30\uAE00\uC790.")),
                React.createElement("div", { className: Styles.row },
                    React.createElement("label", null, "\uC554\uD638"),
                    React.createElement("input", { type: "password", value: this.state.password, onChange: function (e) { return _this.setState({ password: e.target.value }); } })),
                React.createElement("div", { className: Styles.row },
                    React.createElement("label", null, "\uC554\uD638 (\uD655\uC778)"),
                    React.createElement("input", { type: "password", value: this.state.passwordCheck, onChange: function (e) {
                            return _this.setState({
                                passwordCheck: e.target.value,
                            });
                        } })),
                React.createElement("button", { type: "submit", className: Styles.signupButton, disabled: this.state.submitted }, "\uD68C\uC6D0 \uAC00\uC785"))));
    };
    Signup.prototype.validate = function () {
        var _a = this.state, username = _a.username, password = _a.password, passwordCheck = _a.passwordCheck;
        if (username.length === 0) {
            return '아이디를 입력하세요.';
        }
        if (username.length > 30 || !username.match(/^[A-Za-z0-9_]+$/)) {
            return '사용할 수 없는 아이디 형식입니다.';
        }
        if (password.length === 0) {
            return '암호를 입력하세요.';
        }
        if (password !== passwordCheck) {
            return '암호 확인을 정확히 입력하세요.';
        }
        return null;
    };
    return Signup;
}(React.Component));
export default AppLayout({ noHero: true }).wrap({
    component: Signup,
    load: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, ({})];
    }); }); },
    renderTitle: function () { return '회원 가입'; },
});
