export function trackPageView() {
    // do nothing for GA4
}
export function trackEvent(event) {
    if (gtag) {
        gtag("event", event.eventAction, {
            event_category: event.eventCategory,
            event_label: event.eventLabel,
            value: event.eventValue,
        });
    }
}
