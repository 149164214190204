var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useState } from "react";
import MuiRating from "@mui/material/Rating";
export function Rating(props) {
    var _a = useState(null), activeValue = _a[0], setActiveValue = _a[1];
    var handleRef = useCallback(function (node) {
        if (node) {
            if (!node._ratingTouchEventHandlers) {
                node._ratingTouchEventHandlers = new TouchEventHandlers(node);
            }
            node._ratingTouchEventHandlers.activeValue = activeValue;
        }
    }, [activeValue]);
    return (React.createElement(MuiRating, __assign({ ref: !props.readOnly ? handleRef : undefined, precision: 0.5, onChangeActive: function (_, value) { return setActiveValue(value); }, size: "large" }, props)));
}
/**
 * https://android.googlesource.com/platform/frameworks/base/+/c5ce225fdf7451badf26eb7fee59300a35f4155d/core/java/android/widget/AbsSeekBar.java#900
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Touch_events
 * https://web.dev/mobile-touchandmouse/
 */
var TouchEventHandlers = /** @class */ (function () {
    function TouchEventHandlers(node) {
        var _this = this;
        this.node = node;
        this.activeValue = null;
        this.touchDownX = null;
        this.isDragging = false;
        this.touchSlop = 8;
        this.touchStart = function (event) {
            _this.touchDownX = event.touches[0].clientX;
        };
        this.touchMove = function (event) {
            if (_this.isDragging) {
                event.preventDefault();
                _this.trackTouchEvent(event);
            }
            else {
                var x = event.touches[0].clientX;
                if (Math.abs(x - _this.touchDownX) > _this.touchSlop) {
                    event.preventDefault();
                    _this.isDragging = true;
                    _this.trackTouchEvent(event);
                }
            }
        };
        this.touchEnd = function (event) {
            if (_this.isDragging) {
                _this.isDragging = false;
                _this.trackTouchEvent(event);
                _this.commitActiveValue();
            }
            _this.triggerMouseLeave();
        };
        this.touchCancel = function () {
            if (_this.isDragging) {
                _this.isDragging = false;
            }
        };
        node.addEventListener('touchstart', this.touchStart, false);
        node.addEventListener('touchmove', this.touchMove, false);
        node.addEventListener('touchend', this.touchEnd, false);
        node.addEventListener('touchcancel', this.touchCancel, false);
    }
    TouchEventHandlers.prototype.trackTouchEvent = function (event) {
        if (event.touches.length === 0)
            return;
        this.node.dispatchEvent(new MouseEvent('mousemove', {
            bubbles: true,
            clientX: event.touches[0].clientX,
        }));
    };
    TouchEventHandlers.prototype.commitActiveValue = function () {
        var selectedValue = this.activeValue;
        if (selectedValue == null)
            return;
        this.node.querySelectorAll('input[type="radio"]').forEach(function (node) {
            if (node.value === selectedValue.toString()) {
                if (!node.checked) {
                    node.click();
                }
            }
        });
    };
    TouchEventHandlers.prototype.triggerMouseLeave = function () {
        // trigger mouseleave (React simulates mouseleave by handling mouse*out*)
        this.node.dispatchEvent(new MouseEvent('mouseout', {
            bubbles: true,
        }));
    };
    return TouchEventHandlers;
}());
