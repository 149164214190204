var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import fetch from 'cross-fetch';
import React from 'react';
import { LoadMore } from './LoadMore';
import Styles from './VideoSearch.less';
function formatDate(t) {
    // YYYY-MM-DDTHH:MM:SS+09:00
    var d = new Date(parseInt(t.substr(0, 4), 10), parseInt(t.substr(5, 2), 10) - 1, parseInt(t.substr(8, 2), 10));
    return d.toLocaleDateString();
}
function shorten(str, limit) {
    if (str.length > limit)
        str = str.substring(0, limit - 3) + '...';
    return str;
}
function VideoSearch(props) {
    return React.createElement(VideoSearchInternal, __assign({ key: props.query }, props));
}
var VideoSearchInternal = /** @class */ (function (_super) {
    __extends(VideoSearchInternal, _super);
    function VideoSearchInternal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isLoading: true,
            hasMore: true,
            result: [],
            page: 0,
        };
        _this._loadMore = function () {
            var page = _this.state.page + 1;
            _this.setState({ isLoading: true });
            var qs = new URLSearchParams({
                query: _this.props.query,
                size: '10',
                page: '' + page,
            });
            fetch('https://dapi.kakao.com/v2/search/vclip?' + qs, {
                headers: {
                    'Authorization': "KakaoAK ".concat(window.__nuri.preloadData.kakaoApiKey),
                    'Content-Type': 'application/json',
                }
            }).then(function (r) { return r.json(); }).then(function (data) {
                var result = _this.state.result.concat(data.documents);
                _this.setState({
                    hasMore: result.length < data.meta.pageable_count,
                    page: page,
                    isLoading: false,
                    result: result,
                });
            });
        };
        return _this;
    }
    VideoSearchInternal.prototype.componentDidMount = function () {
        this._loadMore();
    };
    VideoSearchInternal.prototype.render = function () {
        var _a = this.state, isLoading = _a.isLoading, result = _a.result, page = _a.page, hasMore = _a.hasMore;
        if (result.length === 0)
            return null;
        var limit = result.length;
        if (page === 1) {
            limit = Math.min(limit, 5);
        }
        return (React.createElement("div", null,
            result.slice(0, limit).map(function (item) { return (React.createElement("a", { href: item.url, target: "_blank", className: Styles.item },
                React.createElement("div", { className: Styles.thumbnail },
                    React.createElement("img", { src: item.thumbnail })),
                React.createElement("div", { className: Styles.itemContent },
                    React.createElement("div", { className: Styles.title }, shorten(item.title, 35)),
                    React.createElement("div", { className: Styles.date }, formatDate(item.datetime))))); }),
            hasMore && (React.createElement(LoadMore, { isLoading: isLoading, loadMoreText: "\uAC80\uC0C9 \uACB0\uACFC \uB354 \uBCF4\uAE30", onClick: this._loadMore }))));
    };
    return VideoSearchInternal;
}(React.Component));
export default VideoSearch;
