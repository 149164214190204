var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import orderBy from 'lodash/orderBy';
import React from 'react';
import formatDate from 'date-fns/format';
import diffDays from 'date-fns/difference_in_calendar_days';
import diffWeeks from 'date-fns/difference_in_calendar_weeks';
import diffMonths from 'date-fns/difference_in_calendar_months';
import { Link } from 'nuri';
import * as util from '../util';
import Styles from './Library.less';
import * as Grid from './Grid';
import { Switch, SwitchItem } from './Switch';
import AddRecordDialog from './AddRecordDialog';
import { trackEvent } from '../Tracking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { Library_CreateRecordDocument } from './__generated__/Library.graphql';
import { LibraryFilter } from './LibraryFilter';
import { serializeUserRouteQuery } from '../UserRouteUtils';
var ENABLE_NEW_ADD_RECORD = false;
function getDateHeader(record) {
    var now = new Date();
    if (!record.updatedAt) {
        return '?';
    }
    var days = diffDays(now, record.updatedAt);
    if (days <= 60) {
        if (days < 1)
            return '오늘';
        else if (days < 2)
            return '어제';
        else if (days < 3)
            return '그저께';
        else if (days < 4)
            return '그끄저께';
        else if (diffWeeks(now, record.updatedAt) === 0)
            return '이번 주';
        else if (diffWeeks(now, record.updatedAt) === 1)
            return '지난 주';
        else if (diffMonths(now, record.updatedAt) === 0)
            return '이번 달';
        else if (diffMonths(now, record.updatedAt) === 1)
            return '지난 달';
    }
    return formatDate(record.updatedAt, 'YYYY/MM');
}
function getIndexChar(s) {
    if (!s)
        return '#';
    s = s.replace(/^the /i, '');
    var ch = s.charAt(0);
    if ('가' <= ch && ch <= '힣') {
        // 쌍자음 처리
        var code = ch.charCodeAt(0);
        var lead = Math.floor((code - 0xac00) / 588);
        if (lead == 1 || lead == 4 || lead == 8 || lead == 10 || lead == 13)
            lead--;
        return String.fromCharCode(0xac00 + lead * 588);
    }
    else if (('a' <= ch && ch <= 'z') || ('A' <= ch && ch <= 'Z')) {
        return ch.toUpperCase();
    }
    else {
        return '#';
    }
}
function groupRecords(records, keyFn) {
    var groups = [];
    var unknownGroup = [];
    var lastKey = null;
    var group = [];
    records.forEach(function (record) {
        var key = keyFn(record);
        if (key == null) {
            unknownGroup.push(record);
        }
        else {
            if (key != lastKey) {
                if (group.length > 0 && lastKey)
                    groups.push({ key: lastKey, items: group });
                lastKey = key;
                group = [];
            }
            group.push(record);
        }
    });
    if (group.length > 0 && lastKey)
        groups.push({ key: lastKey, items: group });
    if (unknownGroup.length)
        groups.push({ key: '?', items: unknownGroup });
    for (var i = 0; i < groups.length; i++)
        groups[i].index = 1 + i;
    return groups;
}
function groupRecordsByTitle(records) {
    records = orderBy(records, function (record) { return getIndexChar(record.title); });
    return groupRecords(records, function (record) { return getIndexChar(record.title); });
}
function groupRecordsByDate(records) {
    records = orderBy(records, function (record) { return record.updatedAt || ''; }, 'desc');
    return groupRecords(records, function (record) { return record.updatedAt ? getDateHeader(record) : null; });
}
function groupRecordsByRating(records) {
    records = orderBy(records, function (record) { return record.rating || 0; }, 'desc');
    return groupRecords(records, function (record) { return record.rating ? "".concat(record.rating, "\uC810") : '별점 없음'; });
}
function LibraryItem(_a) {
    var _b;
    var record = _a.record;
    return (React.createElement("div", { className: "".concat(Styles.groupItem, " item-").concat((_b = record.statusType) === null || _b === void 0 ? void 0 : _b.toLowerCase()) },
        React.createElement(Link, { to: "/records/".concat(record.databaseId, "/") },
            React.createElement("span", { className: "item-title" }, record.title),
            React.createElement("span", { className: "item-status" }, util.getStatusTextGql(record)),
            record.hasNewerEpisode && React.createElement("span", { className: "item-updated" }, "up!"))));
}
var Library = /** @class */ (function (_super) {
    __extends(Library, _super);
    function Library() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            mobileFilterVisible: false,
            showAddModal: false,
        };
        _this._getLinkParams = function (updates) {
            var basePath = "/users/".concat(encodeURIComponent(_this.props.user.name), "/");
            return {
                to: basePath,
                queryParams: serializeUserRouteQuery(__assign(__assign({}, _this.props.query), updates)),
            };
        };
        _this._scrollToGroup = function (event) {
            event.preventDefault();
            var id = event.target.hash.substring(1);
            var el = document.getElementById(id);
            if (!el)
                return;
            window.scrollBy(0, el.getBoundingClientRect().top - 50);
        };
        _this._toggleMobileFilter = function (event) {
            event.preventDefault();
            _this.setState({ mobileFilterVisible: !_this.state.mobileFilterVisible });
        };
        _this._showAddModal = function (event) {
            if (ENABLE_NEW_ADD_RECORD)
                return;
            event.preventDefault();
            _this.setState({ showAddModal: true });
        };
        _this._recordCreated = function () {
            _this.props.onAddRecord();
            trackEvent({
                eventCategory: 'Record',
                eventAction: 'Create',
                eventLabel: 'Library',
            });
            _this.setState({ showAddModal: false });
        };
        return _this;
    }
    Library.prototype.render = function () {
        var _this = this;
        var orderBy = this.props.query.orderBy;
        var records = this.props.user.records.nodes;
        var groups = [];
        if (orderBy === "DATE" /* RecordOrder.Date */) {
            groups = groupRecordsByDate(records);
        }
        else if (orderBy === "TITLE" /* RecordOrder.Title */) {
            groups = groupRecordsByTitle(records);
        }
        else if (orderBy === "RATING" /* RecordOrder.Rating */) {
            groups = groupRecordsByRating(records);
        }
        var filters = this.props.user.recordFilters;
        var totalCount = filters.totalCount, filteredCount = filters.filteredCount;
        var canEdit = this.props.user.isCurrentUser;
        return (React.createElement(Grid.Row, { className: Styles.library },
            React.createElement(Grid.Column, { size: 3, pull: "left" },
                canEdit && (React.createElement("div", { className: Styles.navButtonGroup },
                    React.createElement(Link, { to: ENABLE_NEW_ADD_RECORD ? "/records/add-new/" : "/records/add/", className: Styles.addRecordButton, onClick: this._showAddModal },
                        React.createElement(FontAwesomeIcon, { icon: faPlus }),
                        " \uC791\uD488 \uCD94\uAC00"),
                    this.state.showAddModal && (
                    /* TODO: automatically set selected filter state */
                    React.createElement(AddRecordDialog, { initialStatusType: "FINISHED" /* StatusType.Finished */, onCancel: function () { return _this.setState({ showAddModal: false }); }, onCreate: this._recordCreated, createRecordMutation: Library_CreateRecordDocument })),
                    React.createElement(Link, { to: "/records/rating/", className: Styles.manageRatingButton },
                        React.createElement(FontAwesomeIcon, { icon: faStar }),
                        " \uBCC4\uC810 \uAD00\uB9AC"))),
                React.createElement("div", { className: Styles.mobileFilterToggle, onClick: this._toggleMobileFilter },
                    totalCount !== filteredCount ? '필터 (사용중)' : '필터',
                    ' ',
                    React.createElement(FontAwesomeIcon, { icon: this.state.mobileFilterVisible
                            ? faCaretUp
                            : faCaretDown })),
                React.createElement("div", { className: Styles.sort },
                    React.createElement(Switch, null,
                        React.createElement(SwitchItem, __assign({ Component: Link }, this._getLinkParams({ orderBy: "DATE" /* RecordOrder.Date */ }), { active: orderBy === "DATE" /* RecordOrder.Date */ }), "\uC2DC\uAC04\uC21C"),
                        React.createElement(SwitchItem, __assign({ Component: Link }, this._getLinkParams({ orderBy: "TITLE" /* RecordOrder.Title */ }), { active: orderBy === "TITLE" /* RecordOrder.Title */ }), "\uC81C\uBAA9\uC21C"),
                        React.createElement(SwitchItem, __assign({ Component: Link }, this._getLinkParams({ orderBy: "RATING" /* RecordOrder.Rating */ }), { active: orderBy === "RATING" /* RecordOrder.Rating */ }), "\uBCC4\uC810\uC21C")),
                    (orderBy === "TITLE" /* RecordOrder.Title */ || orderBy === "RATING" /* RecordOrder.Rating */) && (React.createElement("div", { className: Styles.toc }, groups.map(function (group) { return (React.createElement("a", { href: '#group' + group.index, key: group.key, onClick: _this._scrollToGroup }, group.key)); })))),
                React.createElement("div", { className: this.state.mobileFilterVisible ? '' : 'hide-mobile' },
                    React.createElement(LibraryFilter, { query: this.props.query, filters: filters, categoryList: this.props.user.categories, canEdit: canEdit, getLinkParams: this._getLinkParams }))),
            React.createElement(Grid.Column, { size: 9, pull: "left" }, totalCount === 0 ? (React.createElement("div", { className: Styles.empty },
                React.createElement("h1", null, "\uC544\uC9C1 \uAE30\uB85D\uC774 \uD558\uB098\uB3C4 \uC5C6\uB124\uC694."),
                canEdit && (React.createElement("p", null,
                    React.createElement(Link, { to: ENABLE_NEW_ADD_RECORD ? "/records/add-new/" : "/records/add/", onClick: this._showAddModal }, "\uC791\uD488 \uCD94\uAC00"),
                    "\uB97C \uB20C\uB7EC \uAC10\uC0C1 \uAE30\uB85D\uC744 \uB4F1\uB85D\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.")))) : (React.createElement(React.Fragment, null, groups.map(function (group) { return (React.createElement("div", { className: Styles.group, key: group.key, id: 'group' + group.index },
                React.createElement("h2", { className: Styles.groupTitle }, group.key),
                React.createElement("div", { className: Styles.groupItems }, group.items.map(function (record) { return (React.createElement(LibraryItem, { key: record.databaseId, record: record })); })))); }))))));
    };
    return Library;
}(React.Component));
export default Library;
