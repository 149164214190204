var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Sentry from '@sentry/react';
import fetch from 'cross-fetch';
import * as CSRF from './CSRF';
import isString from 'lodash/isString';
import graphqlRequest, { ClientError } from 'graphql-request';
function serializeParams(params) {
    if (!params) {
        return params;
    }
    var result = {};
    for (var k in params) {
        var v = params[k];
        if (typeof v !== 'undefined') {
            result[k] = isString(v) ? v : JSON.stringify(v);
        }
    }
    return result;
}
function handleError(request, response, thrownError, customErrorHandling) {
    return __awaiter(this, void 0, void 0, function () {
        var responseText, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (customErrorHandling)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (response === null || response === void 0 ? void 0 : response.text())];
                case 2:
                    responseText = _a.sent();
                    Sentry.captureMessage((thrownError === null || thrownError === void 0 ? void 0 : thrownError.message) || (response === null || response === void 0 ? void 0 : response.statusText), {
                        extra: {
                            method: request.method,
                            url: request.url,
                            params: request.params,
                            status: response === null || response === void 0 ? void 0 : response.status,
                            error: thrownError || (response === null || response === void 0 ? void 0 : response.statusText),
                            response: responseText && responseText.substring(0, 100),
                        },
                    });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    try {
                        Sentry.captureException(e_1);
                    }
                    catch (e2) {
                        // ignore
                    }
                    return [3 /*break*/, 4];
                case 4:
                    if (thrownError instanceof ApiError) {
                        alert(thrownError.message);
                    }
                    else {
                        alert('서버 오류로 요청에 실패했습니다.');
                    }
                    return [2 /*return*/];
            }
        });
    });
}
var ApiError = /** @class */ (function (_super) {
    __extends(ApiError, _super);
    function ApiError(status, payload) {
        var _this = _super.call(this, payload.message) || this;
        _this.status = status;
        _this.payload = payload;
        // https://www.dannyguo.com/blog/how-to-fix-instanceof-not-working-for-custom-errors-in-typescript/
        Object.setPrototypeOf(_this, ApiError.prototype);
        return _this;
    }
    return ApiError;
}(Error));
export { ApiError };
function _fetch(method, url, params, customErrorHandling) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var originalUrl, qs_1, response, init, e_2, r, body, e_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    originalUrl = url;
                    if (method === 'GET') {
                        qs_1 = new URLSearchParams((_a = serializeParams(params)) !== null && _a !== void 0 ? _a : {}).toString();
                        if (qs_1)
                            url += '?' + qs_1;
                    }
                    response = null;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 9, , 11]);
                    init = {
                        method: method,
                        headers: {
                            'Accept': 'application/json',
                        },
                        // 'same-origin' does not work on Firefox private browsing
                        credentials: 'include',
                    };
                    if (!(method !== 'GET')) return [3 /*break*/, 6];
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, CSRF.refresh()];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    e_2 = _b.sent();
                    Sentry.captureException(e_2);
                    return [3 /*break*/, 5];
                case 5:
                    if (typeof params !== 'undefined') {
                        init.body = JSON.stringify(params);
                    }
                    init.headers = __assign(__assign({}, init.headers), { 'Content-Type': 'application/json', 'X-CSRF-Token': CSRF.getToken() });
                    _b.label = 6;
                case 6: return [4 /*yield*/, fetch(url, init)];
                case 7:
                    r = _b.sent();
                    response = r.clone(); // store cloned response for double body consume in handleError
                    return [4 /*yield*/, r.json()];
                case 8:
                    body = _b.sent();
                    if (r.ok) {
                        return [2 /*return*/, body];
                    }
                    else {
                        throw new ApiError(r.status, body);
                    }
                    return [3 /*break*/, 11];
                case 9:
                    e_3 = _b.sent();
                    return [4 /*yield*/, handleError({
                            method: method,
                            url: originalUrl,
                            params: params,
                        }, response, e_3, customErrorHandling)];
                case 10:
                    _b.sent();
                    throw e_3;
                case 11: return [2 /*return*/];
            }
        });
    });
}
export function get(url, params, customErrorHandling) {
    if (params === void 0) { params = {}; }
    if (customErrorHandling === void 0) { customErrorHandling = false; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, _fetch('GET', url, params, customErrorHandling)];
        });
    });
}
export function postJSON(url, params, customErrorHandling) {
    if (params === void 0) { params = {}; }
    if (customErrorHandling === void 0) { customErrorHandling = false; }
    return _fetch('POST', url, params, customErrorHandling);
}
export function doDelete(url) {
    return _fetch('DELETE', url, undefined, false);
}
export function createFrontendSession(params) {
    return postJSON('/api/fe/sessions', params);
}
export function deleteFrontendSession() {
    return doDelete('/api/fe/sessions');
}
// GraphQL
export function graphql(doc, variables, customErrorHandling) {
    if (customErrorHandling === void 0) { customErrorHandling = false; }
    return __awaiter(this, void 0, void 0, function () {
        var e_4, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, CSRF.refresh()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_4 = _a.sent();
                    Sentry.captureException(e_4);
                    return [3 /*break*/, 3];
                case 3:
                    _a.trys.push([3, 5, , 9]);
                    return [4 /*yield*/, graphqlRequest('/api/graphql', doc, variables, {
                            'Accept': 'application/json',
                            'X-CSRF-Token': CSRF.getToken(),
                        })];
                case 4: return [2 /*return*/, _a.sent()];
                case 5:
                    e_5 = _a.sent();
                    if (!(e_5 instanceof ClientError)) return [3 /*break*/, 7];
                    return [4 /*yield*/, handleGqlError(e_5.request, e_5.response, e_5, customErrorHandling)];
                case 6:
                    _a.sent();
                    return [3 /*break*/, 8];
                case 7:
                    if (!customErrorHandling) {
                        alert("\uC11C\uBC84 \uC624\uB958\uB85C \uC694\uCCAD\uC5D0 \uC2E4\uD328\uD588\uC2B5\uB2C8\uB2E4.");
                    }
                    _a.label = 8;
                case 8: throw e_5;
                case 9: return [2 /*return*/];
            }
        });
    });
}
function handleGqlError(request, response, thrownError, customErrorHandling) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var message, responseText;
        return __generator(this, function (_c) {
            if (customErrorHandling)
                return [2 /*return*/];
            message = (_b = (_a = response.errors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message;
            try {
                responseText = "";
                try {
                    responseText = JSON.stringify(response);
                }
                catch (e) {
                    // ignore
                }
                Sentry.captureMessage(message !== null && message !== void 0 ? message : "GraphQL Error (Code: ".concat(response.status, ")"), {
                    extra: {
                        query: request.query,
                        variables: request.variables,
                        status: response === null || response === void 0 ? void 0 : response.status,
                        error: thrownError,
                        response: responseText.substring(0, 100),
                    },
                });
            }
            catch (e) {
                try {
                    Sentry.captureException(e);
                }
                catch (e2) {
                    // ignore
                }
            }
            if (message) {
                alert(message);
            }
            else {
                alert("\uC11C\uBC84 \uC624\uB958\uB85C \uC694\uCCAD\uC5D0 \uC2E4\uD328\uD588\uC2B5\uB2C8\uB2E4. [".concat(response.status, "]"));
            }
            return [2 /*return*/];
        });
    });
}
