var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Link } from 'nuri';
import * as util from '../util';
import Styles from './LibraryFilter.module.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
export function LibraryFilter(_a) {
    var query = _a.query, getLinkParams = _a.getLinkParams, filters = _a.filters, categoryList = _a.categoryList, canEdit = _a.canEdit;
    var statusTypeStats = filters.statusType.items.reduce(function (acc, it) { acc[it.key] = it.count; return acc; }, {});
    var categoryStats = filters.categoryId.items.reduce(function (acc, it) { acc[it.key] = it.count; return acc; }, {});
    return (React.createElement("div", { className: Styles.filter },
        React.createElement("div", { className: Styles.filterGroup },
            React.createElement("div", { className: Styles.filterGroupTitle }, "\uC0C1\uD0DC"),
            React.createElement("div", { className: query.statusType == null
                    ? Styles.filterGroupItemActive
                    : Styles.filterGroupItem },
                React.createElement(Link, __assign({}, getLinkParams({ statusType: null })),
                    "\uC804\uCCB4 (",
                    filters.statusType.allCount,
                    ")")),
            ["WATCHING" /* StatusType.Watching */, "FINISHED" /* StatusType.Finished */, "SUSPENDED" /* StatusType.Suspended */, "INTERESTED" /* StatusType.Interested */].map(function (statusType) { return (React.createElement("div", { className: query.statusType === statusType
                    ? Styles.filterGroupItemActive
                    : Styles.filterGroupItem },
                React.createElement(Link, __assign({}, getLinkParams({ statusType: statusType })),
                    util.GQL_STATUS_TYPE_TEXT[statusType],
                    " (",
                    statusTypeStats[statusType] || 0,
                    ")"))); })),
        React.createElement("div", { className: Styles.filterGroup },
            React.createElement("div", { className: Styles.filterGroupTitle }, "\uBD84\uB958"),
            React.createElement("div", { className: query.categoryId == null
                    ? Styles.filterGroupItemActive
                    : Styles.filterGroupItem },
                React.createElement(Link, __assign({}, getLinkParams({ categoryId: null })),
                    "\uC804\uCCB4 (",
                    filters.categoryId.allCount,
                    ")")),
            [{ databaseId: '0', name: '지정 안함' }]
                .concat(categoryList)
                .map(function (category) { return (React.createElement("div", { className: query.categoryId === category.databaseId
                    ? Styles.filterGroupItemActive
                    : Styles.filterGroupItem },
                React.createElement(Link, __assign({}, getLinkParams({
                    categoryId: category.databaseId,
                })),
                    category.name,
                    " (",
                    categoryStats[category.databaseId] || 0,
                    ")"))); }),
            ' ',
            canEdit && (React.createElement(Link, { to: "/records/category/", className: Styles.manageCategoryButton },
                React.createElement(FontAwesomeIcon, { icon: faCog }),
                " \uBD84\uB958 \uAD00\uB9AC")))));
}
