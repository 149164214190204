var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Sentry from '@sentry/react';
import fetch from 'cross-fetch';
import * as CSRF from './CSRF';
import { ApiError } from './API';
function handleError(request, response, thrownError, customErrorHandling) {
    return __awaiter(this, void 0, void 0, function () {
        var responseText, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (customErrorHandling)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (response === null || response === void 0 ? void 0 : response.text())];
                case 2:
                    responseText = _a.sent();
                    Sentry.captureMessage((thrownError === null || thrownError === void 0 ? void 0 : thrownError.message) || (response === null || response === void 0 ? void 0 : response.statusText), {
                        extra: {
                            url: request.url,
                            params: request.params,
                            status: response === null || response === void 0 ? void 0 : response.status,
                            error: thrownError || (response === null || response === void 0 ? void 0 : response.statusText),
                            response: responseText && responseText.substring(0, 100),
                        },
                    });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    try {
                        Sentry.captureException(e_1);
                    }
                    catch (e2) {
                        // ignore
                    }
                    return [3 /*break*/, 4];
                case 4:
                    // TODO: toast
                    if (thrownError instanceof ApiError) {
                        alert(thrownError.message);
                    }
                    else {
                        alert('서버 오류로 요청에 실패했습니다.');
                    }
                    return [2 /*return*/];
            }
        });
    });
}
function _fetch(url, params, customErrorHandling) {
    return __awaiter(this, void 0, void 0, function () {
        var response, init, e_2, r, body, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    response = null;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 8, , 10]);
                    init = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                        },
                        // 'same-origin' does not work on Firefox private browsing
                        credentials: 'include',
                    };
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, CSRF.refresh()];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    e_2 = _a.sent();
                    Sentry.captureException(e_2);
                    return [3 /*break*/, 5];
                case 5:
                    if (typeof params !== 'undefined') {
                        init.body = JSON.stringify(params);
                    }
                    init.headers = __assign(__assign({}, init.headers), { 'Content-Type': 'application/json', 'X-CSRF-Token': CSRF.getToken() });
                    return [4 /*yield*/, fetch(url, init)];
                case 6:
                    r = _a.sent();
                    response = r.clone(); // store cloned response for double body consume in handleError
                    return [4 /*yield*/, r.json()];
                case 7:
                    body = _a.sent();
                    if (r.ok) {
                        return [2 /*return*/, body];
                    }
                    else {
                        throw new ApiError(r.status, body);
                    }
                    return [3 /*break*/, 10];
                case 8:
                    e_3 = _a.sent();
                    return [4 /*yield*/, handleError({
                            url: url,
                            params: params,
                        }, response, e_3, customErrorHandling)];
                case 9:
                    _a.sent();
                    throw e_3;
                case 10: return [2 /*return*/];
            }
        });
    });
}
function create() {
    return {
        call: function (path, params, options) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, _fetch(path, params, (_a = options === null || options === void 0 ? void 0 : options.customErrorHandling) !== null && _a !== void 0 ? _a : false)];
                });
            });
        }
    };
}
export var API = create();
