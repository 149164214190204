var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { UserLayout } from '../layouts/UserLayout';
import * as Layout from '../ui/Layout';
import { LoadMore } from '../ui/LoadMore';
import { Post } from '../ui/Post';
import Styles from './UserHistory.less';
import { UserHistoryRouteDocument, UserHistoryRoute_MorePostsDocument } from './__generated__/UserHistory.graphql';
function getDateHeader(post) {
    if (!post.updatedAt) {
        return '';
    }
    var date = new Date(post.updatedAt);
    return date.getFullYear() + '/' + (date.getMonth() + 1);
}
function UserHistory(_a) {
    var data = _a.data, writeData = _a.writeData, loader = _a.loader;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var postConnection = data.user.posts;
    var posts = postConnection.nodes;
    var groups = [];
    var unknownGroup = [];
    var lastKey = '', group = null;
    for (var _i = 0, posts_1 = posts; _i < posts_1.length; _i++) {
        var post = posts_1[_i];
        if (!post.updatedAt) {
            unknownGroup.push(post);
        }
        else {
            var key = getDateHeader(post);
            if (key != lastKey) {
                if (group)
                    groups.push({ key: lastKey, items: group });
                lastKey = key;
                group = [];
            }
            if (group)
                group.push(post);
        }
    }
    if (group && group.length > 0)
        groups.push({ key: lastKey, items: group });
    if (unknownGroup.length)
        groups.push({ key: '?', items: unknownGroup });
    function _loadMore() {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, loader.graphql(UserHistoryRoute_MorePostsDocument, {
                                userId: data.user.databaseId,
                                beforeId: (posts === null || posts === void 0 ? void 0 : posts.length) ? (_a = posts[posts.length - 1]) === null || _a === void 0 ? void 0 : _a.databaseId : null,
                            })];
                    case 1:
                        result = _b.sent();
                        writeData(function (data) {
                            data.user.posts.nodes = data.user.posts.nodes.concat(result.user.posts.nodes);
                            data.user.posts.hasMore = result.user.posts.hasMore;
                        });
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(Layout.CenteredFullWidth, null,
        groups.map(function (group) { return (React.createElement("div", { className: Styles.group },
            React.createElement("div", { className: Styles.groupTitle }, group.key),
            group.items.map(function (post) { return (React.createElement(Post, { post: post, showUser: false, showStatusType: true })); }))); }),
        postConnection.hasMore && (React.createElement(LoadMore, { isLoading: isLoading, onClick: _loadMore }))));
}
var routeHandler = UserLayout.wrap({
    component: UserHistory,
    load: function (_a) {
        var loader = _a.loader, params = _a.params, notFound = _a.notFound;
        return __awaiter(this, void 0, void 0, function () {
            var username, _b, user, data;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        username = params.username;
                        return [4 /*yield*/, loader.graphql(UserHistoryRouteDocument, { username: username })];
                    case 1:
                        _b = _c.sent(), user = _b.user, data = __rest(_b, ["user"]);
                        if (!user) {
                            return [2 /*return*/, notFound()];
                        }
                        return [2 /*return*/, __assign(__assign({}, data), { user: user })];
                }
            });
        });
    },
});
export default routeHandler;
