var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Modal } from 'react-overlays';
import { graphql } from '../API';
import connectTwitter from '../connectTwitter';
import * as Typeahead from './Typeahead';
import { Switch, SwitchItem } from './Switch';
import LoginDialog from './LoginDialog';
import { StatusInput } from './StatusInput';
import ModalStyles from './Modal.less';
import Styles from './AddRecordDialog.less';
import { setLastPublishTwitter } from '../Prefs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Rating } from './Rating';
import { AddRecordDialogDocument } from './__generated__/AddRecordDialog.graphql';
var CategorySelect = /** @class */ (function (_super) {
    __extends(CategorySelect, _super);
    function CategorySelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._onChange = function (event) {
            if (_this.props.onChange)
                _this.props.onChange(event.target.value);
        };
        return _this;
    }
    CategorySelect.prototype.render = function () {
        var _a = this.props, selectedId = _a.selectedId, categoryList = _a.categoryList, props = __rest(_a, ["selectedId", "categoryList"]);
        return (React.createElement("select", __assign({}, props, { value: selectedId, onChange: this._onChange }),
            React.createElement("option", { value: "" }, "\uC9C0\uC815 \uC548\uD568"),
            categoryList.map(function (category) { return (React.createElement("option", { value: category.databaseId }, category.name)); })));
    };
    return CategorySelect;
}(React.Component));
var AddRecord = /** @class */ (function (_super) {
    __extends(AddRecord, _super);
    function AddRecord(props) {
        var _this = this;
        var _a;
        _this = _super.call(this, props) || this;
        _this._titleEl = null;
        _this._onRatingChange = function (event, rating) {
            _this.setState({ rating: rating });
        };
        _this._onCategoryChange = function (categoryId) {
            _this.setState({ selectedCategoryId: categoryId });
        };
        _this._onStatusTypeChange = function (statusType) {
            var status = statusType === "INTERESTED" /* StatusType.Interested */ ? '' : _this.state.status;
            var rating = statusType === "INTERESTED" /* StatusType.Interested */ ? null : _this.state.rating;
            _this.setState({
                statusType: statusType,
                status: status,
                rating: rating,
            });
        };
        _this._onStatusChange = function (status) {
            _this.setState({ status: status });
        };
        _this._onCommentChange = function (event) {
            _this.setState({ comment: event.target.value });
        };
        _this._onPublishTwitterChange = function (event) {
            if (!_this.state.currentUser.isTwitterConnected) {
                connectTwitter().then(function () {
                    _this.setState(function (state) { return ({
                        publishTwitter: true,
                        currentUser: __assign(__assign({}, state.currentUser), { isTwitterConnected: true }),
                    }); });
                });
            }
            else {
                _this.setState({ publishTwitter: event.target.checked });
            }
        };
        _this._onSubmit = function (event) {
            event.preventDefault();
            if (_this.state.isRequesting)
                return;
            _this.setState({ isRequesting: true });
            setLastPublishTwitter(_this.state.publishTwitter);
            graphql(_this.props.createRecordMutation, { input: {
                    title: _this._titleEl.value,
                    statusType: _this.state.statusType,
                    status: _this.state.status,
                    categoryId: _this.state.selectedCategoryId !== '' ? _this.state.selectedCategoryId : null,
                    comment: _this.state.comment,
                    rating: _this.state.rating,
                    publishTwitter: _this.state.publishTwitter,
                } })
                .then(function (result) {
                _this.props.onCreate(result.createRecord);
                _this.setState({ isRequesting: false });
            }, function () {
                _this.setState({ isRequesting: false });
            });
        };
        _this.state = {
            selectedCategoryId: '',
            statusType: (_a = props.initialStatusType) !== null && _a !== void 0 ? _a : "WATCHING" /* StatusType.Watching */,
            status: '',
            comment: '',
            rating: null,
            publishTwitter: false,
            isRequesting: false,
            currentUser: null,
        };
        return _this;
    }
    AddRecord.prototype.render = function () {
        var _this = this;
        var _a;
        var currentUser = this.state.currentUser;
        if (!currentUser) {
            return null;
        }
        var onCancel = this.props.onCancel;
        return (React.createElement(Modal, { show: true, className: ModalStyles.container, renderBackdrop: function (props) { return React.createElement("div", __assign({ className: ModalStyles.backdrop }, props)); } },
            React.createElement("div", { className: ModalStyles.dialog, style: { overflow: 'visible' } },
                React.createElement("div", { className: ModalStyles.header },
                    React.createElement("button", { className: ModalStyles.closeButton, onClick: onCancel },
                        React.createElement(FontAwesomeIcon, { icon: faTimesCircle, size: "lg" })),
                    React.createElement("h2", { className: ModalStyles.title }, "\uC791\uD488 \uCD94\uAC00")),
                React.createElement("form", { className: Styles.form, onSubmit: this._onSubmit },
                    React.createElement("div", { className: Styles.field },
                        React.createElement("label", null, "\uC791\uD488\uBA85"),
                        React.createElement("input", { ref: function (el) { return _this._titleEl = el; }, defaultValue: this.props.initialTitle })),
                    React.createElement("div", { className: Styles.field },
                        React.createElement("label", null, "\uAC10\uC0C1 \uC0C1\uD0DC"),
                        React.createElement(Switch, { flex: true, value: this.state.statusType, onChange: this._onStatusTypeChange },
                            React.createElement(SwitchItem, { value: "INTERESTED" /* StatusType.Interested */ }, "\uBCFC \uC608\uC815"),
                            React.createElement(SwitchItem, { value: "WATCHING" /* StatusType.Watching */ }, "\uBCF4\uB294 \uC911"),
                            React.createElement(SwitchItem, { value: "FINISHED" /* StatusType.Finished */ }, "\uC644\uB8CC"),
                            React.createElement(SwitchItem, { value: "SUSPENDED" /* StatusType.Suspended */ }, "\uC911\uB2E8"))),
                    this.state.statusType !== "INTERESTED" /* StatusType.Interested */ && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: Styles.field },
                            React.createElement("label", null, "\uC9C4\uD589\uB960 (\uC120\uD0DD \uC0AC\uD56D)"),
                            React.createElement(StatusInput, { value: this.state.status, onChange: this._onStatusChange })),
                        React.createElement("div", { className: Styles.field },
                            React.createElement("label", null, "\uBCC4\uC810"),
                            React.createElement(Rating, { value: (_a = this.state.rating) !== null && _a !== void 0 ? _a : undefined, onChange: this._onRatingChange })))),
                    React.createElement("div", { className: Styles.field },
                        React.createElement("label", null, "\uBD84\uB958"),
                        React.createElement(CategorySelect, { categoryList: currentUser.categories, selectedId: this.state.selectedCategoryId, onChange: this._onCategoryChange })),
                    React.createElement("div", { className: Styles.field },
                        React.createElement("label", null, "\uAC10\uC0C1\uD3C9 (\uC120\uD0DD \uC0AC\uD56D)"),
                        React.createElement("textarea", { value: this.state.comment, onChange: this._onCommentChange }))),
                React.createElement("button", { className: ModalStyles.fullConfirmButton, disabled: this.state.isRequesting, onClick: this._onSubmit }, "\uCD94\uAC00"),
                React.createElement("div", { style: { clear: 'both' } }))));
    };
    AddRecord.prototype.componentDidMount = function () {
        this._load();
    };
    AddRecord.prototype._load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var currentUser;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, graphql(AddRecordDialogDocument)];
                    case 1:
                        currentUser = (_a.sent()).currentUser;
                        if (!currentUser) {
                            alert('로그인 후 추가할 수 있습니다.');
                            LoginDialog.open();
                            this.props.onCancel();
                            return [2 /*return*/];
                        }
                        this.setState({ currentUser: currentUser }, function () {
                            // FIXME
                            setTimeout(function () {
                                if (_this._titleEl) {
                                    Typeahead.initSuggest(_this._titleEl);
                                    if (!_this.props.initialTitle) {
                                        _this._titleEl.focus();
                                    }
                                }
                            }, 10);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return AddRecord;
}(React.Component));
export default AddRecord;
