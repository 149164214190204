var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { UserLayout_CurrentUserFragmentDoc, UserLayout_UserFragmentDoc } from '../../ui/__generated__/UserLayout.graphql';
import { PostComposer_RecordFragmentDoc } from '../../ui/__generated__/PostComposer.graphql';
import { PostComment_PostFragmentDoc } from '../../ui/__generated__/GqlPostComment.graphql';
export var RecordRoute_Header_CategoryFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "RecordRoute_header_category" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "Category" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "name" } }] } }] };
export var RecordRoute_HeaderFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "RecordRoute_header" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "Record" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "title" } }, { "kind": "Field", "name": { "kind": "Name", "value": "rating" } }, { "kind": "Field", "name": { "kind": "Name", "value": "user" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "isCurrentUser" } }, { "kind": "Field", "name": { "kind": "Name", "value": "categories" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "RecordRoute_header_category" } }] } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "category" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }] } }] } }] };
export var RecordRoute_RecordFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "RecordRoute_record" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "Record" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "user" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "isCurrentUser" } }, { "kind": "Field", "name": { "kind": "Name", "value": "name" } }] } }, { "kind": "Field", "alias": { "kind": "Name", "value": "layoutUser" }, "name": { "kind": "Name", "value": "user" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "UserLayout_user" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "title" } }, { "kind": "Field", "name": { "kind": "Name", "value": "updatedAt" } }, { "kind": "FragmentSpread", "name": { "kind": "Name", "value": "RecordRoute_header" } }, { "kind": "FragmentSpread", "name": { "kind": "Name", "value": "PostComposer_record" } }] } }] };
export var RecordRoute_PostFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "RecordRoute_post" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "Post" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "statusType" } }, { "kind": "Field", "name": { "kind": "Name", "value": "status" } }, { "kind": "Field", "name": { "kind": "Name", "value": "comment" } }, { "kind": "Field", "name": { "kind": "Name", "value": "containsSpoiler" } }, { "kind": "Field", "name": { "kind": "Name", "value": "updatedAt" } }, { "kind": "FragmentSpread", "name": { "kind": "Name", "value": "PostComment_post" } }] } }] };
export var RecordRouteDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "RecordRoute" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "recordId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "alias": { "kind": "Name", "value": "layoutCurrentUser" }, "name": { "kind": "Name", "value": "currentUser" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "UserLayout_currentUser" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "currentUser" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "isTwitterConnected" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "record" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "id" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "recordId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "RecordRoute_record" } }] } }] } }], UserLayout_CurrentUserFragmentDoc.definitions, true), RecordRoute_RecordFragmentDoc.definitions, true), UserLayout_UserFragmentDoc.definitions, true), RecordRoute_HeaderFragmentDoc.definitions, true), RecordRoute_Header_CategoryFragmentDoc.definitions, true), PostComposer_RecordFragmentDoc.definitions, true) };
export var RecordRoute_PostsDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "RecordRoute_posts" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "recordId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "record" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "id" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "recordId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "posts" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "beforeId" }, "value": { "kind": "NullValue" } }, { "kind": "Argument", "name": { "kind": "Name", "value": "count" }, "value": { "kind": "NullValue" } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "nodes" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "RecordRoute_post" } }] } }] } }] } }] } }], RecordRoute_PostFragmentDoc.definitions, true), PostComment_PostFragmentDoc.definitions, true) };
export var RecordRoute_UpdateTitleDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "RecordRoute_updateTitle" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "UpdateRecordTitleInput" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "updateRecordTitle" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "input" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "record" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "RecordRoute_record" } }] } }] } }] } }], RecordRoute_RecordFragmentDoc.definitions, true), UserLayout_UserFragmentDoc.definitions, true), RecordRoute_HeaderFragmentDoc.definitions, true), RecordRoute_Header_CategoryFragmentDoc.definitions, true), PostComposer_RecordFragmentDoc.definitions, true) };
export var RecordRoute_UpdateCategoryDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "RecordRoute_updateCategory" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "UpdateRecordCategoryIdInput" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "updateRecordCategoryId" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "input" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "record" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "RecordRoute_record" } }] } }] } }] } }], RecordRoute_RecordFragmentDoc.definitions, true), UserLayout_UserFragmentDoc.definitions, true), RecordRoute_HeaderFragmentDoc.definitions, true), RecordRoute_Header_CategoryFragmentDoc.definitions, true), PostComposer_RecordFragmentDoc.definitions, true) };
export var RecordRoute_UpdateRatingDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "RecordRoute_updateRating" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "UpdateRecordRatingInput" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "updateRecordRating" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "input" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "record" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "RecordRoute_record" } }] } }] } }] } }], RecordRoute_RecordFragmentDoc.definitions, true), UserLayout_UserFragmentDoc.definitions, true), RecordRoute_HeaderFragmentDoc.definitions, true), RecordRoute_Header_CategoryFragmentDoc.definitions, true), PostComposer_RecordFragmentDoc.definitions, true) };
export var RecordRoute_CreatePostDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "RecordRoute_createPost" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "CreatePostInput" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "createPost" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "input" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "post" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }] } }] } }] } }] };
export var RecordRoute_DeletePostDocument = { "kind": "Document", "definitions": __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "RecordRoute_deletePost" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "DeletePostInput" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "deletePost" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "input" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "record" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "RecordRoute_record" } }] } }] } }] } }], RecordRoute_RecordFragmentDoc.definitions, true), UserLayout_UserFragmentDoc.definitions, true), RecordRoute_HeaderFragmentDoc.definitions, true), RecordRoute_Header_CategoryFragmentDoc.definitions, true), PostComposer_RecordFragmentDoc.definitions, true) };
export var RecordRoute_DeleteRecordDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "RecordRoute_deleteRecord" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "DeleteRecordInput" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "deleteRecord" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "input" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "input" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "deleted" } }] } }] } }] };
