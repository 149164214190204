import * as React from 'react';
import { Link } from 'nuri';
import * as util from '../util';
import { TimeAgo } from './TimeAgo';
import GqlPostComment from './GqlPostComment';
import Styles from './Post.module.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
export function Post(_a) {
    var post = _a.post, _b = _a.showUser, showUser = _b === void 0 ? true : _b, _c = _a.showTitle, showTitle = _c === void 0 ? true : _c, _d = _a.showStatusType, showStatusType = _d === void 0 ? false : _d, _e = _a.highlighted, highlighted = _e === void 0 ? false : _e;
    return (React.createElement("div", { className: highlighted ? Styles.highlightedPost : Styles.post },
        React.createElement("div", { className: Styles.meta },
            showUser && (React.createElement(Link, { to: '/users/' + post.user.name + '/', className: Styles.user }, post.user.name)),
            showUser &&
                (showTitle || post.status) && (React.createElement(FontAwesomeIcon, { icon: faCaretRight, className: Styles.metaSeparator })),
            showTitle && (React.createElement(Link, { to: util.getWorkURL(post.record.title), className: Styles.work }, post.record.title)),
            (showStatusType || post.status) && (React.createElement("span", { className: showTitle ? '' : Styles.episodeWithoutTitle }, showStatusType
                ? util.getStatusTextGql(post)
                : util.getStatusDisplayGql(post))),
            React.createElement(Link, { to: util.getPostURLGql(post), className: Styles.time }, post.updatedAt ? React.createElement(TimeAgo, { time: new Date(post.updatedAt) }) : '#')),
        React.createElement(GqlPostComment, { post: post, className: Styles.comment })));
}
