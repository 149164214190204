var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import * as Grid from './Grid';
export var Stack = function (_a) {
    var children = _a.children;
    return (React.createElement("div", { style: { position: 'relative' } }, React.Children.map(children, function (child) {
        return child &&
            React.isValidElement(child) &&
            React.cloneElement(child, {
                style: __assign(__assign({}, child.props.style), { position: 'absolute', top: 0, left: 0, right: 0 }),
            });
    })));
};
export var CenteredFullWidth = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement("div", __assign({}, props),
        React.createElement(Grid.Row, null,
            React.createElement(Grid.Column, { size: Grid.getColumns() }, children))));
};
