var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import * as WorkViews from '../ui/WorkViews';
import { getStatusDisplayGql } from '../util';
import { AppLayout } from '../layouts/AppLayout';
import { Post as PostComponent } from '../ui/Post';
import { PostRouteDocument, PostRoute_PostsDocument, PostRoute_RefetchDocument } from './__generated__/Post.graphql';
import Styles from './Post.module.less';
function Post(_a) {
    var _b;
    var data = _a.data, writeData = _a.writeData, loader = _a.loader;
    var post = data.post, currentUser = data.currentUser, postsData = data.postsData;
    var work = post.work, episode = post.episode;
    var postConnection = (_b = postsData === null || postsData === void 0 ? void 0 : postsData.work) === null || _b === void 0 ? void 0 : _b.posts;
    var posts = postConnection === null || postConnection === void 0 ? void 0 : postConnection.nodes;
    useEffect(function () {
        loadMorePosts();
    }, [post.databaseId]);
    function loadMorePosts() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, loader.graphql(PostRoute_PostsDocument, {
                            workId: work.databaseId,
                            beforeId: (posts === null || posts === void 0 ? void 0 : posts.length) ? (_a = posts[posts.length - 1]) === null || _a === void 0 ? void 0 : _a.databaseId : null,
                            episode: (_b = episode === null || episode === void 0 ? void 0 : episode.number) !== null && _b !== void 0 ? _b : null,
                        })];
                    case 1:
                        result = _c.sent();
                        writeData(function (data) {
                            if (data.postsData) {
                                data.postsData.work.posts.nodes = data.postsData.work.posts.nodes.concat(result.work.posts.nodes);
                                data.postsData.work.posts.hasMore = result.work.posts.hasMore;
                            }
                            else {
                                data.postsData = result;
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function reload() {
        return __awaiter(this, void 0, void 0, function () {
            var newData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loader.graphql(PostRoute_RefetchDocument, { postId: post.databaseId })];
                    case 1:
                        newData = _a.sent();
                        writeData(function (data) {
                            Object.assign(data, newData);
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(WorkViews.Work, { work: work, chart: data, currentUser: currentUser, onRecordChange: reload }, episode ? React.createElement(React.Fragment, null,
        React.createElement(WorkViews.Episodes, { work: work, activeEpisodeNumber: episode === null || episode === void 0 ? void 0 : episode.number }),
        React.createElement(WorkViews.EpisodeHeader, { episode: episode }),
        React.createElement(PostComponent, { post: post, showTitle: false, highlighted: true }),
        React.createElement(WorkViews.WorkIndex, { postConnection: postConnection, loadMorePosts: loadMorePosts, excludePostID: post.databaseId })) : React.createElement(React.Fragment, null,
        React.createElement("div", { className: Styles.postOnly },
            React.createElement(PostComponent, { post: post, showTitle: false, highlighted: true })),
        React.createElement(WorkViews.Episodes, { work: work }),
        React.createElement(WorkViews.WorkIndex, { postConnection: postConnection, loadMorePosts: loadMorePosts }))));
}
var routeHandler = AppLayout.wrap({
    component: Post,
    load: function (_a) {
        var params = _a.params, loader = _a.loader, notFound = _a.notFound;
        return __awaiter(this, void 0, void 0, function () {
            var id, _b, post, data;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        id = params.id;
                        return [4 /*yield*/, loader.graphql(PostRouteDocument, { postId: id })];
                    case 1:
                        _b = _c.sent(), post = _b.post, data = __rest(_b, ["post"]);
                        if (!post)
                            return [2 /*return*/, notFound()];
                        return [2 /*return*/, __assign(__assign({}, data), { post: post })];
                }
            });
        });
    },
    renderTitle: function (_a) {
        var post = _a.post;
        var work = post.work;
        return "".concat(post.user.name, " \uC0AC\uC6A9\uC790 > ").concat(work.title, " ").concat(getStatusDisplayGql(post));
    },
    renderMeta: function (_a) {
        var post = _a.post;
        var work = post.work;
        return {
            og_url: "/-".concat(post.databaseId),
            og_type: 'article',
            og_image: work === null || work === void 0 ? void 0 : work.imageUrl,
            tw_url: "/-".concat(post.databaseId),
            tw_image: work === null || work === void 0 ? void 0 : work.imageUrl,
        };
    },
});
export default routeHandler;
