import React, { useEffect, useState } from 'react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import koLocale from 'date-fns/locale/ko';
export function TimeAgo(props) {
    var _a = useState(function () { return distanceInWordsToNow(props.time, {
        addSuffix: true,
        locale: koLocale,
    }); }), relativeTime = _a[0], setRelativeTime = _a[1];
    useEffect(function () {
        var handle = setInterval(function () { return setRelativeTime(distanceInWordsToNow(props.time, {
            addSuffix: true,
            locale: koLocale,
        })); }, 60 * 1000);
        return function () { return clearInterval(handle); };
    }, []);
    // Store title in state to workaround difference between server and client
    var _b = useState(), title = _b[0], setTitle = _b[1];
    useEffect(function () {
        setTitle(new Date(props.time).toLocaleString());
    }, [props.time]);
    return React.createElement("span", { title: title }, relativeTime);
}
