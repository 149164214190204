var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as util from '../util';
import { StatusInput } from './StatusInput';
import Styles from './PostComposer.less';
var PostComposer = /** @class */ (function (_super) {
    __extends(PostComposer, _super);
    function PostComposer() {
        var _this = this;
        var _a, _b;
        _this = _super.apply(this, arguments) || this;
        _this.state = {
            statusType: (_a = _this.props.record.statusType) !== null && _a !== void 0 ? _a : "FINISHED" /* StatusType.Finished */,
            status: util.plusOne((_b = _this.props.record.status) !== null && _b !== void 0 ? _b : ''),
            comment: '',
            publishTwitter: false,
            containsSpoiler: false,
        };
        _this._commonOnChange = function (event) {
            var _a;
            _this.setState((_a = {}, _a[event.target.name] = event.target.value, _a));
        };
        _this._onStatusChange = function (newValue) {
            _this.setState({ status: newValue });
        };
        _this._onContainsSpoilerChange = function (event) {
            _this.setState({ containsSpoiler: event.target.checked });
        };
        _this._onSubmit = function (event) {
            event.preventDefault();
            if (_this._submitting)
                return;
            _this._submitting = true;
            var _a = _this.state, status = _a.status, statusType = _a.statusType, comment = _a.comment, containsSpoiler = _a.containsSpoiler, publishTwitter = _a.publishTwitter;
            _this.props.onSave({
                status: status,
                statusType: statusType,
                comment: comment,
                containsSpoiler: containsSpoiler,
                publishTwitter: publishTwitter,
            }).then(function () { return _this._submitting = false; }, function () { return _this._submitting = false; });
        };
        return _this;
    }
    PostComposer.prototype.render = function () {
        var record = this.props.record;
        var currentStatus;
        if (record.status) {
            currentStatus = (React.createElement("span", { className: Styles.currentProgress },
                record.status,
                " \u2192 "));
        }
        return (React.createElement("form", { className: Styles.postComposer },
            React.createElement("div", { className: Styles.progress },
                React.createElement("select", { name: "statusType", value: this.state.statusType, onChange: this._commonOnChange },
                    React.createElement("option", { value: "WATCHING" /* StatusType.Watching */ }, "\uBCF4\uB294 \uC911"),
                    React.createElement("option", { value: "FINISHED" /* StatusType.Finished */ }, "\uC644\uB8CC"),
                    React.createElement("option", { value: "SUSPENDED" /* StatusType.Suspended */ }, "\uC911\uB2E8"),
                    React.createElement("option", { value: "INTERESTED" /* StatusType.Interested */ }, "\uBCFC \uC608\uC815")),
                ' @ ',
                currentStatus,
                React.createElement(StatusInput, { value: this.state.status, onChange: this._onStatusChange })),
            React.createElement("textarea", { name: "comment", rows: 3, cols: 30, autoFocus: true, placeholder: "\uAC10\uC0C1\uD3C9 (\uC120\uD0DD\uC0AC\uD56D)", value: this.state.comment, onChange: this._commonOnChange }),
            React.createElement("div", { className: Styles.actions },
                React.createElement("label", null,
                    React.createElement("input", { type: "checkbox", checked: this.state.containsSpoiler, onChange: this._onContainsSpoilerChange }),
                    ' 내용 누설 포함'),
                React.createElement("button", { type: "button", onClick: this._onSubmit }, "\uAE30\uB85D \uCD94\uAC00"))));
    };
    return PostComposer;
}(React.Component));
export { PostComposer };
