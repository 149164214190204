var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'react-overlays';
import ModalStyles from './Modal.less';
import LoginForm from './LoginForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
var LoginDialog = /** @class */ (function (_super) {
    __extends(LoginDialog, _super);
    function LoginDialog() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoginDialog.open = function (props) {
        var container = document.getElementById('dialog-container');
        if (!container) {
            container = document.createElement('div');
            container.id = 'dialog-container';
            document.body.appendChild(container);
        }
        ReactDOM.render(React.createElement(LoginDialog, __assign({ onClose: LoginDialog.close }, props)), container);
    };
    LoginDialog.close = function () {
        var container = document.getElementById('dialog-container');
        if (!container)
            return;
        ReactDOM.unmountComponentAtNode(container);
        document.body.removeChild(container);
    };
    LoginDialog.prototype.render = function () {
        return (React.createElement(Modal, { show: true, className: ModalStyles.container, renderBackdrop: function (props) { return React.createElement("div", __assign({ className: ModalStyles.backdrop }, props)); }, onHide: this.props.onClose },
            React.createElement("div", { className: ModalStyles.dialog },
                React.createElement("div", { className: ModalStyles.header },
                    React.createElement("button", { className: ModalStyles.closeButton, onClick: this.props.onClose },
                        React.createElement(FontAwesomeIcon, { icon: faTimesCircle, size: "lg" })),
                    React.createElement("h2", { className: ModalStyles.title }, "\uB85C\uADF8\uC778")),
                React.createElement(LoginForm, { next: this.props.next }))));
    };
    return LoginDialog;
}(React.Component));
export default LoginDialog;
