var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import Styles from './LoadMore.less';
import useIntersectionObserver from './useIntersectionObserver';
export function LoadMore(_a) {
    var isLoading = _a.isLoading, onClick = _a.onClick, _b = _a.loadMoreText, loadMoreText = _b === void 0 ? '더 보기' : _b;
    if (isLoading)
        return React.createElement("div", { className: Styles.loadMore }, "\uB85C\uB4DC \uC911...");
    else
        return (React.createElement("div", { className: Styles.loadMore, onClick: onClick }, loadMoreText));
}
export function AutoLoadMore(props) {
    var _a;
    var sentinelEl = React.useRef(null);
    var entry = useIntersectionObserver(sentinelEl, {
        threshold: [0],
        rootMargin: "0px 0px ".concat((_a = props.scrollThreshold) !== null && _a !== void 0 ? _a : 300, "px 0px"),
    });
    var shouldLoadMore = entry ? entry.isIntersecting : false;
    React.useEffect(function () {
        if (shouldLoadMore) {
            props.onClick();
        }
    }, [shouldLoadMore]);
    return (React.createElement("div", { ref: sentinelEl },
        React.createElement(LoadMore, __assign({}, props))));
}
