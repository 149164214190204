var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Styles from './LoginForm.less';
import ModalStyles from './Modal.less';
import { createFrontendSession } from '../API';
import { API } from '../ApiClient';
var LoginForm = /** @class */ (function (_super) {
    __extends(LoginForm, _super);
    function LoginForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.usernameInput = React.createRef();
        _this.passwordInput = React.createRef();
        _this.state = {
            submitted: false,
            errors: false,
            isPersistent: false,
        };
        _this._onSubmit = function (event) {
            event.preventDefault();
            _this.setState({ submitted: true });
            var username = _this.usernameInput.current.value;
            API.call('/api/v5/LoginForm/authenticate', {
                username: username,
                password: _this.passwordInput.current.value,
                persistent: _this.state.isPersistent,
            }).then(function (authResult) { return createFrontendSession({ authResult: authResult }).then(function () {
                if (_this.props.next) {
                    location.href = _this.props.next.redirectToUser
                        ? "/users/".concat(username, "/")
                        : _this.props.next;
                }
                else {
                    location.reload();
                }
            }); }, function () {
                _this.setState({
                    submitted: false,
                });
            });
        };
        return _this;
    }
    LoginForm.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            this.state.errors && (React.createElement("div", { className: Styles.loginErrors }, "\uC5C6\uB294 \uC544\uC774\uB514\uAC70\uB098 \uC554\uD638\uAC00 \uD2C0\uB838\uC2B5\uB2C8\uB2E4. \uB2E4\uC2DC \uC2DC\uB3C4\uD574\uBCF4\uC138\uC694.")),
            React.createElement("form", { method: "post", action: "/login/", onSubmit: this._onSubmit },
                React.createElement("div", { className: Styles.loginRowGroup },
                    React.createElement("div", { className: Styles.loginRow },
                        React.createElement("label", null, "\uC544\uC774\uB514"),
                        React.createElement("input", { name: "username", maxLength: 30, autoFocus: true, ref: this.usernameInput })),
                    React.createElement("div", { className: Styles.loginRow },
                        React.createElement("label", null, "\uC554\uD638"),
                        React.createElement("input", { type: "password", name: "password", ref: this.passwordInput }))),
                !this.state.submitted && (React.createElement("button", { type: "submit", className: ModalStyles.confirmButton }, "\uB85C\uADF8\uC778")),
                React.createElement("div", { className: Styles.loginCheckRow },
                    React.createElement("label", null,
                        React.createElement("input", { type: "checkbox", checked: this.state.isPersistent, onChange: function (e) {
                                return _this.setState({
                                    isPersistent: e.target.checked,
                                });
                            } }),
                        "90\uC77C \uB3D9\uC548 \uC790\uB3D9 \uB85C\uADF8\uC778")))));
    };
    return LoginForm;
}(React.Component));
export default LoginForm;
