import React from 'react';
import * as Sentry from '@sentry/react';
export var ErrorBoundary = function (_a) {
    var children = _a.children;
    return (React.createElement(Sentry.ErrorBoundary, { fallback: React.createElement(ErrorFallback, null) }, children));
};
function ErrorFallback() {
    return React.createElement(React.Fragment, null,
        React.createElement("h1", null, "\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4."),
        React.createElement("p", null, "\uD398\uC774\uC9C0 \uC0C8\uB85C \uACE0\uCE68 \uD6C4 \uB2E4\uC2DC \uC2DC\uB3C4\uD574\uBCF4\uC138\uC694."),
        React.createElement("p", null,
            "\uBC1C\uC0DD\uD55C \uC624\uB958\uB294 \uAD00\uB9AC\uC790\uC5D0\uAC8C \uC804\uC1A1\uB418\uC5C8\uC73C\uBA70, \uBB38\uC81C\uAC00 \uACC4\uC18D \uBC1C\uC0DD\uD55C\uB2E4\uBA74",
            ' ',
            React.createElement("a", { href: "/support/" }, "\uBC84\uADF8 \uC2E0\uACE0"),
            " \uBD80\uD0C1\uB4DC\uB9BD\uB2C8\uB2E4."));
}
