export function zerofill(n) {
    var s = String(n);
    if (s.length == 1)
        s = '0' + s;
    return s;
}
export function getTime(date) {
    return zerofill(date.getHours()) + ':' + zerofill(date.getMinutes());
}
var HOURS = [];
for (var h = 0; h < 24; h++) {
    var result;
    if (h < 12)
        result = '오전 ' + h + '시';
    else if (h == 12)
        result = '정오';
    else
        result = '오후 ' + (h - 12) + '시';
    HOURS[h] = result;
}
var TIME_FORMAT = null;
try {
    TIME_FORMAT = new Intl.DateTimeFormat('ko-u-hc-h11', {
        hour: 'numeric', minute: 'numeric',
        timeZone: 'Asia/Seoul'
    });
}
catch (e) { }
export function formatTime(value) {
    if (TIME_FORMAT) {
        return TIME_FORMAT.format(value);
    }
    var result = HOURS[value.getHours()];
    var m = value.getMinutes();
    if (m > 0) {
        result += ' ' + zerofill(m) + '분';
    }
    return result;
}
export function plusOne(val) {
    var matches = val.match(/(\d+)[^\d]*$/);
    if (!matches)
        return val;
    var add1 = (parseInt(matches[1], 10) + 1).toString();
    var digits = matches[1].length;
    if (add1.length < digits)
        for (var i = 0; i < digits - add1.length; i++)
            add1 = '0' + add1;
    return val.replace(/(\d+)([^\d]*)$/, add1 + '$2');
}
export function getStatusDisplayGql(record) {
    var _a;
    return formatStatus((_a = record.status) !== null && _a !== void 0 ? _a : '');
}
export function formatStatus(status) {
    return "".concat(status).trim().replace(/([0-9]+)$/, '$1화');
}
export var STATUS_TYPE_TEXT = {
    watching: '보는 중',
    finished: '완료',
    interested: '볼 예정',
    suspended: '중단',
};
export var GQL_STATUS_TYPE_TEXT = {
    WATCHING: '보는 중',
    FINISHED: '완료',
    INTERESTED: '볼 예정',
    SUSPENDED: '중단',
};
export function getStatusTextGql(record) {
    var status = getStatusDisplayGql(record);
    if (record.statusType !== 'WATCHING' || status === '') {
        var statusTypeText = GQL_STATUS_TYPE_TEXT[record.statusType];
        if (status !== '') {
            status += ' (' + statusTypeText + ')';
        }
        else {
            status = statusTypeText;
        }
    }
    return status;
}
export function getWorkURL(title) {
    return '/works/' + encodeURIComponent(title) + '/';
}
export function getPostURLGql(post) {
    return '/-' + ('databaseId' in post ? post.databaseId : post.id);
}
export var SOURCE_TYPE_MAP = {
    MANGA: '만화 원작',
    ORIGINAL: '오리지널 작품',
    LIGHT_NOVEL: '라노베 원작',
    GAME: '게임 원작',
    FOUR_KOMA: '4컷 만화 원작',
    VISUAL_NOVEL: '비주얼 노벨 원작',
    NOVEL: '소설 원작',
};
export function formatPeriod(period) {
    return "".concat(period.year, "\uB144 ").concat(period.month, "\uC6D4");
}
