var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { GlobalHeader } from '../ui/GlobalHeader';
import { AppLayoutDocument } from './__generated__/AppLayout.graphql';
import { ErrorBoundary } from './ErrorBoundary';
import { createLayout } from '../LayoutWrapper';
export function AppLayout(globalHeaderProps) {
    return createLayout({
        component: function (props) {
            var _a;
            return (React.createElement(ErrorBoundary, null,
                React.createElement(GlobalHeader, __assign({ currentUsername: (_a = props.layoutData.currentUser) === null || _a === void 0 ? void 0 : _a.name }, globalHeaderProps)),
                props.children));
        },
        load: function (request) {
            return request.loader.graphql(AppLayoutDocument);
        }
    });
}
AppLayout.wrap = AppLayout().wrap;
