var _a, _b;
var SORT_MAPPING = {
    'date': "DATE" /* RecordOrder.Date */,
    'title': "TITLE" /* RecordOrder.Title */,
    'rating': "RATING" /* RecordOrder.Rating */,
};
var INVERSE_SORT_MAPPING = (_a = {},
    _a["DATE" /* RecordOrder.Date */] = 'date',
    _a["TITLE" /* RecordOrder.Title */] = 'title',
    _a["RATING" /* RecordOrder.Rating */] = 'rating',
    _a);
var STATUS_TYPE_MAPPING = {
    'watching': "WATCHING" /* StatusType.Watching */,
    'finished': "FINISHED" /* StatusType.Finished */,
    'suspended': "SUSPENDED" /* StatusType.Suspended */,
    'interested': "INTERESTED" /* StatusType.Interested */,
};
var INVERSE_STATUS_TYPE_MAPPING = (_b = {},
    _b["WATCHING" /* StatusType.Watching */] = 'watching',
    _b["FINISHED" /* StatusType.Finished */] = 'finished',
    _b["SUSPENDED" /* StatusType.Suspended */] = 'suspended',
    _b["INTERESTED" /* StatusType.Interested */] = 'interested',
    _b);
export function normalizeUserRouteQuery(query) {
    var _a, _b;
    var type = query.type, category = query.category, sort = query.sort;
    return {
        orderBy: (_a = SORT_MAPPING[sort]) !== null && _a !== void 0 ? _a : "DATE" /* RecordOrder.Date */,
        statusType: (_b = STATUS_TYPE_MAPPING[type]) !== null && _b !== void 0 ? _b : null,
        categoryId: category != null && category !== '' ? category : null,
    };
}
export function serializeUserRouteQuery(query) {
    var result = {
        sort: INVERSE_SORT_MAPPING[query.orderBy]
    };
    if (query.statusType != null) {
        result.type = INVERSE_STATUS_TYPE_MAPPING[query.statusType];
    }
    if (query.categoryId != null) {
        result.category = query.categoryId;
    }
    return result;
}
