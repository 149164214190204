var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { CurrentUserLayout } from '../layouts/CurrentUserLayout';
import AddRecordDialog from '../ui/AddRecordDialog';
import { trackEvent } from '../Tracking';
import * as Mutations from '../Mutations';
import { AddRecordRouteDocument, AddRecord_CreateRecordDocument } from './__generated__/AddRecord.graphql';
var AddRecord = /** @class */ (function (_super) {
    __extends(AddRecord, _super);
    function AddRecord() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._onCreate = function (result) {
            trackEvent({
                eventCategory: 'Record',
                eventAction: 'Create',
                eventLabel: _this.props.data.referrer,
            });
            Mutations.records.next({
                id: result.record.databaseId,
                userId: result.record.user.databaseId,
                workId: result.record.work.databaseId,
            });
            _this._returnToUser();
        };
        _this._returnToUser = function () {
            var basePath = "/users/".concat(encodeURIComponent(_this.props.data.currentUser.name), "/");
            _this.props.controller.load({ path: basePath, query: {} }, { stacked: false, returnToParent: true });
        };
        return _this;
    }
    AddRecord.prototype.render = function () {
        return (React.createElement(AddRecordDialog, { initialTitle: this.props.data.title, onCancel: this._returnToUser, onCreate: this._onCreate, createRecordMutation: AddRecord_CreateRecordDocument }));
    };
    return AddRecord;
}(React.Component));
var routeHandler = CurrentUserLayout.wrap({
    component: AddRecord,
    unwrapLayoutOnStacked: true,
    load: function (_a) {
        var loader = _a.loader, params = _a.params, query = _a.query;
        return __awaiter(this, void 0, void 0, function () {
            var _b, currentUser, data;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, loader.graphql(AddRecordRouteDocument)];
                    case 1:
                        _b = _c.sent(), currentUser = _b.currentUser, data = __rest(_b, ["currentUser"]);
                        // TODO: redirect to login page
                        if (!currentUser) {
                            throw new Error('Login required.');
                        }
                        return [2 /*return*/, __assign(__assign({}, data), { currentUser: currentUser, title: params.title, referrer: query.ref || 'AddRecord' })];
                }
            });
        });
    },
});
export default routeHandler;
