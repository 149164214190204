var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { createApp } from 'nuri';
import LoginDialog from './ui/LoginDialog';
import IndexRoute from './routes/Index';
import SignupRoute from './routes/Signup';
import PostRoute from './routes/Post';
import WorkRoute from './routes/Work';
import WorkEpisodeRoute from './routes/WorkEpisode';
import TableRoute from './routes/Table';
import SettingsRoute from './routes/Settings';
import UserRoute from './routes/User';
import UserHistoryRoute from './routes/UserHistory';
import UserTableRoute from './routes/UserTable';
import RecordRoute from './routes/Record';
import AddRecordRoute from './routes/AddRecord';
// import NewAddRecordRoute from './routes/NewAddRecord';
import ManageCategoryRoute from './routes/ManageCategory';
import ManageRatingRoute from './routes/ManageRating';
import { GetCurrentTablePeriodDocument } from './__generated__/routes.graphql';
import { AppLayout } from './layouts/AppLayout';
var app = createApp();
app.title = function (routeTitle) {
    return routeTitle + (routeTitle ? ' - ' : '') + '애니메타';
};
app.route('/', IndexRoute);
app.route('/login/', AppLayout.wrap({
    component: function () { return React.createElement(LoginDialog, { next: "/" }); },
    load: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, ({})];
    }); }); },
    renderTitle: function () { return '로그인'; },
}));
app.route('/signup/', SignupRoute);
app.route('/-:id', PostRoute);
app.route('/works/:title+/ep/:episode/', WorkEpisodeRoute);
app.route('/works/:title+/', WorkRoute);
app.route('/table/', {
    load: function (_a) {
        var redirect = _a.redirect, loader = _a.loader;
        return __awaiter(void 0, void 0, void 0, function () {
            var result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, loader.graphql(GetCurrentTablePeriodDocument)];
                    case 1:
                        result = _b.sent();
                        return [2 /*return*/, redirect("/table/".concat(result.currentTablePeriod.period, "/"))];
                }
            });
        });
    },
});
app.route('/table/:period/', TableRoute);
app.route('/settings/', SettingsRoute);
app.route('/users/:username/', UserRoute);
app.route('/users/:username/history/', UserHistoryRoute);
app.route('/users/:username/table/:period/', UserTableRoute);
// app.route('/records/add-new/', NewAddRecordRoute);
app.route('/records/add/', AddRecordRoute);
app.route('/records/add/:title+/', AddRecordRoute);
app.route('/records/category/', ManageCategoryRoute);
app.route('/records/rating/', ManageRatingRoute);
app.route('/records/:recordId/', RecordRoute);
if (process.env.NODE_ENV === 'development') {
    app.route('/_debug', require('./routes/Debug').default);
}
export default app;
