var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* global confirm */
import * as React from 'react';
import { Modal } from 'react-overlays';
import { Link } from 'nuri';
import * as util from '../util';
import { TimeAgo } from '../ui/TimeAgo';
import { PostComposer } from '../ui/PostComposer';
import * as Typeahead from '../ui/Typeahead';
import PostComment from '../ui/GqlPostComment';
import Styles from '../ui/RecordDetail.module.less';
import connectTwitter from '../connectTwitter';
import { UserLayout } from '../layouts/UserLayout';
import { CenteredFullWidth } from '../ui/Layout';
import ModalStyles from '../ui/Modal.less';
import { trackEvent } from '../Tracking';
import { setLastPublishTwitter } from '../Prefs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import { Rating } from '../ui/Rating';
import { RecordRouteDocument, RecordRoute_CreatePostDocument, RecordRoute_DeletePostDocument, RecordRoute_DeleteRecordDocument, RecordRoute_PostsDocument, RecordRoute_UpdateCategoryDocument, RecordRoute_UpdateRatingDocument, RecordRoute_UpdateTitleDocument } from './__generated__/Record.graphql';
var TitleEditView = /** @class */ (function (_super) {
    __extends(TitleEditView, _super);
    function TitleEditView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.titleInput = React.createRef();
        _this._onSave = function () {
            _this.props.onSave(_this.titleInput.current.value);
        };
        _this._onCancel = function (event) {
            event.preventDefault();
            _this.props.onCancel();
        };
        return _this;
    }
    TitleEditView.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var typeahead;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Typeahead.initSuggest(this.titleInput.current)];
                    case 1:
                        typeahead = _a.sent();
                        typeahead.on('keypress', function (event) {
                            if (event.keyCode == 13) {
                                _this._onSave();
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    TitleEditView.prototype.render = function () {
        return (React.createElement("div", { className: Styles.titleForm },
            React.createElement("input", { ref: this.titleInput, defaultValue: this.props.originalTitle }),
            React.createElement("button", { onClick: this._onSave }, "\uC800\uC7A5"),
            ' ',
            React.createElement("a", { href: "#", onClick: this._onCancel }, "\uCDE8\uC18C")));
    };
    return TitleEditView;
}(React.Component));
var CategoryEditView = /** @class */ (function (_super) {
    __extends(CategoryEditView, _super);
    function CategoryEditView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._onChange = function (event) {
            var categoryId = event.target.value;
            _this.props.onChange(categoryId);
        };
        return _this;
    }
    CategoryEditView.prototype.render = function () {
        var _this = this;
        var _a;
        var name = '지정 안함';
        if (this.props.selectedId) {
            name = this.props.categoryList.filter(function (category) { return category.databaseId == _this.props.selectedId; })[0].name;
        }
        return (React.createElement("span", { className: Styles.categoryForm },
            React.createElement("label", null, "\uBD84\uB958: "),
            name,
            " ",
            React.createElement(FontAwesomeIcon, { icon: faCaretDown }),
            React.createElement("select", { value: (_a = this.props.selectedId) !== null && _a !== void 0 ? _a : '', onChange: this._onChange },
                React.createElement("option", { value: "" }, "\uC9C0\uC815 \uC548\uD568"),
                this.props.categoryList.map(function (category) { return (React.createElement("option", { value: category.databaseId }, category.name)); }))));
    };
    return CategoryEditView;
}(React.Component));
var HeaderView = /** @class */ (function (_super) {
    __extends(HeaderView, _super);
    function HeaderView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isEditingTitle: false,
            isSavingRating: false,
        };
        _this._onTitleEditButtonClick = function (event) {
            event.preventDefault();
            _this.setState({ isEditingTitle: true });
        };
        _this._onTitleSave = function (title) {
            _this.props.onTitleChange(title).then(function () {
                _this.setState({ isEditingTitle: false });
            });
        };
        _this._onDelete = function (event) {
            event.preventDefault();
            _this.props.onDelete();
        };
        _this._updateRating = function (event, newRating) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            isSavingRating: true,
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.props.onRatingChange(newRating)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this.setState({
                            isSavingRating: false,
                        });
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    HeaderView.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        var record = this.props.record;
        var canEdit = (_b = (_a = record.user) === null || _a === void 0 ? void 0 : _a.isCurrentUser) !== null && _b !== void 0 ? _b : false;
        var title = (React.createElement("h1", { className: Styles.title },
            React.createElement(Link, { to: util.getWorkURL(record.title) }, record.title)));
        if (!canEdit) {
            return (React.createElement("div", { className: Styles.header },
                title,
                this.props.record.rating && (React.createElement("div", { className: Styles.readOnlyRating },
                    React.createElement(Rating, { value: this.props.record.rating, readOnly: true }))),
                React.createElement("div", { style: { clear: 'both' } })));
        }
        var titleEditor, editTitleButton;
        if (this.state.isEditingTitle) {
            titleEditor = (React.createElement(TitleEditView, { originalTitle: record.title, onSave: this._onTitleSave, onCancel: function () { return _this.setState({ isEditingTitle: false }); } }));
        }
        else {
            editTitleButton = (React.createElement("a", { href: "#", className: Styles.editTitleButton, onClick: this._onTitleEditButtonClick }, "\uC81C\uBAA9 \uC218\uC815"));
        }
        var toolbar = (React.createElement("div", { className: Styles.toolbar },
            React.createElement("span", { className: Styles.ratingForm },
                React.createElement(Rating, { defaultValue: (_c = this.props.record.rating) !== null && _c !== void 0 ? _c : undefined, onChange: this._updateRating, disabled: this.state.isSavingRating })),
            editTitleButton,
            React.createElement("a", { href: "#", className: Styles.deleteButton, onClick: this._onDelete }, "\uC0AD\uC81C"),
            React.createElement(CategoryEditView, { categoryList: (_e = (_d = record.user) === null || _d === void 0 ? void 0 : _d.categories) !== null && _e !== void 0 ? _e : [], selectedId: (_g = (_f = record.category) === null || _f === void 0 ? void 0 : _f.databaseId) !== null && _g !== void 0 ? _g : null, onChange: this.props.onCategoryChange })));
        return (React.createElement("div", { className: Styles.header },
            titleEditor || title,
            toolbar,
            React.createElement("div", { style: { clear: 'both' } })));
    };
    return HeaderView;
}(React.Component));
function PostView(_a) {
    var post = _a.post, canEdit = _a.canEdit, canDelete = _a.canDelete, onDelete = _a.onDelete;
    return (React.createElement("div", { className: "".concat(Styles.post, " ").concat(post.comment ? '' : Styles.noComment) },
        React.createElement("div", { className: Styles.postProgress }, util.getStatusTextGql(post)),
        React.createElement(PostComment, { post: post, className: Styles.postComment, showSpoiler: canEdit }),
        React.createElement("div", { className: Styles.postMeta },
            post.containsSpoiler && (React.createElement("span", { className: Styles.spoilerMark },
                React.createElement(FontAwesomeIcon, { icon: faMicrophoneSlash, size: "sm" }))),
            React.createElement(Link, { to: util.getPostURLGql(post), className: Styles.postTime }, post.updatedAt ? React.createElement(TimeAgo, { time: new Date(post.updatedAt) }) : '#'),
            canDelete && (React.createElement("span", { className: Styles.deletePostButton, onClick: onDelete }, "\uC9C0\uC6B0\uAE30")))));
}
function DeleteRecordModal(_a) {
    var record = _a.record, onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    return (React.createElement(Modal, { show: true, className: ModalStyles.container, renderBackdrop: function (props) { return React.createElement("div", __assign({ className: ModalStyles.backdrop }, props)); }, onHide: onCancel },
        React.createElement("div", { className: ModalStyles.dialog },
            React.createElement("div", { className: ModalStyles.header },
                React.createElement("h2", { className: ModalStyles.title }, "\uAE30\uB85D \uC0AD\uC81C")),
            React.createElement("p", null,
                "'",
                record.title,
                "'\uC5D0 \uB300\uD55C \uAE30\uB85D\uC744 \uBAA8\uB450 \uC0AD\uC81C\uD569\uB2C8\uB2E4. "),
            React.createElement("p", null,
                "\uC8FC\uC758: ",
                React.createElement("strong", null, "\uC77C\uB2E8 \uC0AD\uC81C\uD558\uBA74 \uB418\uB3CC\uB9B4 \uC218 \uC5C6\uC73C\uB2C8"),
                " \uC2E0\uC911\uD558\uAC8C \uC0DD\uAC01\uD558\uC138\uC694."),
            React.createElement("button", { className: ModalStyles.cancelButton, onClick: onCancel }, "\uCDE8\uC18C"),
            React.createElement("button", { className: ModalStyles.dangerConfirmButton, onClick: onConfirm }, "\uC0AD\uC81C"))));
}
function Record(props) {
    return React.createElement(RecordBase, __assign({ key: props.data.record.databaseId }, props));
}
var RecordBase = /** @class */ (function (_super) {
    __extends(RecordBase, _super);
    function RecordBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            posts: [],
            showDeleteModal: false,
        };
        _this.loadPosts = function (props) {
            _this.setState({
                posts: [],
                showDeleteModal: false,
            });
            props.loader.graphql(RecordRoute_PostsDocument, { recordId: props.data.record.databaseId }).then(function (data) {
                var _a, _b;
                _this.setState({
                    posts: (_b = (_a = data.record) === null || _a === void 0 ? void 0 : _a.posts.nodes) !== null && _b !== void 0 ? _b : [],
                });
            });
        };
        _this._updateTitle = function (title) {
            return _this.props.loader.graphql(RecordRoute_UpdateTitleDocument, {
                input: {
                    recordId: _this.props.data.record.databaseId,
                    title: title
                }
            }).then(function (result) {
                _this.props.writeData(function (data) {
                    data.record = result.updateRecordTitle.record;
                });
            });
        };
        _this._updateCategory = function (categoryID) {
            return _this.props.loader.graphql(RecordRoute_UpdateCategoryDocument, {
                input: {
                    recordId: _this.props.data.record.databaseId,
                    categoryId: categoryID !== '' ? categoryID : null,
                }
            }).then(function (result) {
                _this.props.writeData(function (data) {
                    data.record = result.updateRecordCategoryId.record;
                });
            });
        };
        _this._updateRating = function (rating) {
            return _this.props.loader.graphql(RecordRoute_UpdateRatingDocument, {
                input: {
                    recordId: _this.props.data.record.databaseId,
                    rating: rating,
                }
            }).then(function (result) {
                _this.props.writeData(function (data) {
                    data.record = result.updateRecordRating.record;
                });
            });
        };
        _this._deleteRecord = function () {
            _this.props.loader.graphql(RecordRoute_DeleteRecordDocument, {
                input: { recordId: _this.props.data.record.databaseId }
            }).then(function () {
                _this._redirectToUser();
            });
        };
        _this._deletePost = function (post) {
            if (confirm('삭제 후에는 복구할 수 없습니다.\n기록을 정말로 삭제하시겠습니까?')) {
                _this.props.loader.graphql(RecordRoute_DeletePostDocument, {
                    input: {
                        postId: post.databaseId,
                    }
                }).then(function (result) {
                    _this.loadPosts(_this.props);
                    _this.props.writeData(function (data) {
                        data.record = result.deletePost.record;
                    });
                    _this.props.writeLayoutData(function (data) {
                        data.user = result.deletePost.record.layoutUser;
                    });
                });
            }
        };
        _this._createPost = function (post) {
            setLastPublishTwitter(post.publishTwitter);
            return _this.props.loader.graphql(RecordRoute_CreatePostDocument, {
                input: __assign(__assign({ recordId: _this.props.data.record.databaseId }, post), { rating: null })
            }).then(function () {
                trackEvent({
                    eventCategory: 'Post',
                    eventAction: 'Create',
                    eventLabel: post.comment ? (post.containsSpoiler ? 'Spoiler' : 'NoSpoiler') : 'NoComment',
                });
                if (post.publishTwitter) {
                    trackEvent({
                        eventCategory: 'Post',
                        eventAction: 'ShareOnTwitter',
                    });
                }
                _this._redirectToUser();
            });
        };
        _this._connectTwitter = function () {
            return connectTwitter().then(function () {
                _this.props.writeData(function (data) {
                    data.currentUser.isTwitterConnected = true;
                });
            });
        };
        _this._redirectToUser = function () {
            var basePath = "/users/".concat(encodeURIComponent(_this.props.data.record.user.name), "/");
            _this.props.controller.load({
                path: basePath,
                query: {},
            });
        };
        return _this;
    }
    RecordBase.prototype.componentDidMount = function () {
        this.loadPosts(this.props);
    };
    RecordBase.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var record = this.props.data.record;
        var posts = this.state.posts;
        var canEdit = (_b = (_a = record.user) === null || _a === void 0 ? void 0 : _a.isCurrentUser) !== null && _b !== void 0 ? _b : false;
        var canDeletePosts = canEdit && posts.length > 1;
        return (React.createElement(CenteredFullWidth, null,
            React.createElement(HeaderView, { key: 'header' + record.databaseId, record: record, onTitleChange: this._updateTitle, onCategoryChange: this._updateCategory, onRatingChange: this._updateRating, onDelete: function () { return _this.setState({ showDeleteModal: true }); } }),
            canEdit && (React.createElement("div", { className: Styles.postComposerContainer },
                React.createElement(PostComposer, { key: "post-composer".concat(record.databaseId, "/").concat(record.updatedAt), record: record, onSave: this._createPost, onTwitterConnect: this._connectTwitter }))),
            React.createElement("div", { className: Styles.posts }, posts.map(function (post) { return (React.createElement(PostView, { key: post.databaseId, post: post, canEdit: canEdit, canDelete: canDeletePosts, onDelete: function () { return _this._deletePost(post); } })); })),
            this.state.showDeleteModal && (React.createElement(DeleteRecordModal, { record: record, onConfirm: this._deleteRecord, onCancel: function () { return _this.setState({ showDeleteModal: false }); } }))));
    };
    return RecordBase;
}(React.Component));
var routeHandler = UserLayout.wrap({
    component: Record,
    load: function (_a) {
        var loader = _a.loader, params = _a.params, notFound = _a.notFound;
        return __awaiter(this, void 0, void 0, function () {
            var recordId, _b, record, data;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        recordId = params.recordId;
                        return [4 /*yield*/, loader.graphql(RecordRouteDocument, { recordId: recordId })];
                    case 1:
                        _b = _c.sent(), record = _b.record, data = __rest(_b, ["record"]);
                        if (!record) {
                            return [2 /*return*/, notFound()];
                        }
                        return [2 /*return*/, __assign(__assign({}, data), { record: record })];
                }
            });
        });
    },
    extractLayoutData: function (data) {
        return {
            currentUser: data.layoutCurrentUser,
            user: data.record.layoutUser
        };
    },
    renderTitle: function (_a, parentTitle) {
        var record = _a.record;
        return "".concat(parentTitle, " > ").concat(record.title);
    },
});
export default routeHandler;
