import { faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Styles from './PostComment.less';
export default function GqlPostComment(props) {
    var _a = useState(false), showSpoiler = _a[0], setShowSpoiler = _a[1];
    // Reset showSpoiler state when post ID changes
    useEffect(function () { return setShowSpoiler(false); }, [props.post.databaseId]);
    var _b = props.post, comment = _b.comment, containsSpoiler = _b.containsSpoiler;
    if (!comment)
        return null;
    return (React.createElement("div", { className: props.className }, containsSpoiler &&
        !showSpoiler &&
        !props.showSpoiler ? (React.createElement("span", { className: Styles.spoilerAlert },
        React.createElement(FontAwesomeIcon, { icon: faMicrophoneSlash }),
        "\uB0B4\uC6A9 \uB204\uC124 \uAC00\uB9BC",
        React.createElement("span", { className: Styles.revealLink, onClick: function () { return setShowSpoiler(true); } }, "\uBCF4\uC774\uAE30"))) : (comment)));
}
