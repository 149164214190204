var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var AddRecordDialog_CategoryFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "AddRecordDialog_category" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "Category" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "databaseId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "name" } }] } }] };
export var AddRecordDialogDocument = { "kind": "Document", "definitions": __spreadArray([{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "AddRecordDialog" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "currentUser" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "isTwitterConnected" } }, { "kind": "Field", "name": { "kind": "Name", "value": "categories" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "AddRecordDialog_category" } }] } }] } }] } }], AddRecordDialog_CategoryFragmentDoc.definitions, true) };
